import React, { useState, useEffect, useRef } from "react"
import { toast } from "react-toastify"
import Skeleton from "react-loading-skeleton"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Form from "react-bootstrap/Form"
import Container from "react-bootstrap/Container"
import { useModel } from "@stem-sims/nexus"
import useBoolean from "../../../helpers/useBoolean"
import { LessonResponse } from "shared/lessons"
import { ClassResponse, StudentResponse } from "shared/types/teacherTypes"
import moduleModel from "shared/routes/moduleRoutes"
import { Module, classGradeRange } from "shared/types/moduleTypes"
import LessonFilterHeader from "./FilterHeader"
import LessonFilter from "./Filter"
import LessonResults from "./Results"
import styles from "./styles.module.scss"
import { setSelectedItemDropdown, setSelectedGradeItem } from "helpers/filterItemSelect"
import { getStudents } from "shared/routes/teacher"
import RelevantHelpModal from "components/Modals/RelevantHelpModal"
import { helpSteps, helpLinkVideos } from "./searchHelpData"

const { infoText } = styles
interface Props {
    onLessonSelection: (lesson: LessonResponse, selectedStudent?: StudentResponse) => any
    //Not defined for LTI classes
    activeClass?: ClassResponse
    selectedCategoryInit?: string | null
    selectedGradeInit?: string[] | null
    gradesInit?: { name: string, selected: boolean, invalid: boolean }[]
    initStandardId?: string,
    viewType: "assignment" | "landing" | "normal"
}

const useAfterEffect = (func, deps) => {
    const didMount = useRef(false)

    useEffect(() => {
        if (didMount.current) func()
        else didMount.current = true
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
}

const getStateFromLocalStorage = (key) => {
    const storage = localStorage.getItem(key);
    if (!storage) return null
    let parsedState = JSON.parse(storage)
    
    // If parsedState is an array of arrays, flatten it
    if (Array.isArray(parsedState) && parsedState.some(Array.isArray)) {
        parsedState = parsedState.flat()
    }
    return parsedState
}
const saveStateToLocalStorage = (key, state) => {
    let stateToSave = state
    // If state is an array of arrays, flatten it before saving
    if (Array.isArray(state) && state.some(Array.isArray)) {
        stateToSave = state.flat()
    }
    localStorage.setItem(key, JSON.stringify(stateToSave))
}

export default function Search({ onLessonSelection, activeClass, selectedCategoryInit=null, selectedGradeInit=null, gradesInit=[], initStandardId=null, viewType="normal" }: Props) {
    const [grades, setGrades] = useState<{ name: string, selected: boolean, invalid: boolean }[]>(gradesInit)

    const [categories, setCategories] = useState<{ name: string, id: number, invalid: boolean, selected: boolean }[]>([])
    const [categoryTopics, setCategoryTopics] = useState<{ name: string, id: number, invalid: boolean, selected: boolean }[]>([])
    const [categorySubtopics, setCategorySubtopics] = useState<{ name: string, id: number, invalid: boolean, selected: boolean }[]>([])

    const [standards, setStandards] = useState<{ name: string, id: string, invalid: boolean, selected: boolean }[]>([])
    const [standardTopics, setStandardTopics] = useState<{ name: string, id: string, invalid: boolean, selected: boolean }[]>([])
    const [standardSubtopics, setStandardSubtopics] = useState<{ name: string, id: string, invalid: boolean, selected: boolean, description: string }[]>([])
    const [standardSubtopic, setStandardSubtopic] = useState<{ name: string, id: string, invalid: boolean, selected: boolean, description: string }>(null)

    const [keywords, setKeywords] = useState<string>("")
    const [modules, setModules] = useState<Module[]>([])
    const keywordRef = React.useRef("")
    const [matchAll, toggleMatchAll] = useBoolean(false)
    const debounceTimeout = React.useRef(null)

    const [students, setStudents] = useState<StudentResponse[]>(null)
    React.useEffect(() => {
        setSelectedStudent(null)
        if (activeClass) {
            getStudents({ classID: activeClass.id }).then(setStudents)
        } else {
            setStudents(null)
        }
    }, [activeClass])

    const [selectedGrades, setSelectedGrade] = useState<string[] | null>(() => {
        const preFilledGrade = getStateFromLocalStorage('selectedGrades') ?? selectedGradeInit
        if(preFilledGrade) saveStateToLocalStorage('selectedGrades', preFilledGrade)
        return preFilledGrade
    })
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(() => {
        const preFilledCategory = selectedCategoryInit ?? getStateFromLocalStorage('selectedCategoryId');
        if(preFilledCategory) saveStateToLocalStorage('selectedCategoryId', preFilledCategory)
        return preFilledCategory
    })
    const [selectedCategoryTopicId, setSelectedCategoryTopicId] = useState<number | null>(() => {
        if(selectedCategoryInit) return null
        const state = getStateFromLocalStorage('selectedCategoryTopicId');
        return state
    })
    const [selectedCategorySubtopicId, setSelectedCategorySubtopicId] = useState<number | null>(() => {
        if(selectedCategoryInit) return null
        const state = getStateFromLocalStorage('selectedCategorySubtopicId')
        return state
    })

    const [selectedStandardId, setSelectedStandard] = useState<string | null>(() => {
        const preFillStandardName = getStateFromLocalStorage('selectedStandardId') ?? initStandardId
        return preFillStandardName
    })
    const [selectedStandardTopicId, setSelectedStandardTopic] = useState<string | null>(() => {
        if(initStandardId) return null
        const initialSelectedStandardTopic = getStateFromLocalStorage('selectedStandardTopicId')
        return initialSelectedStandardTopic
    })
    const [selectedStandardSubtopicId, setSelectedStandardSubtopic] = useState<string | null>(() => {
        if(initStandardId) return null
        const initialSelectedStandardSubtopic = getStateFromLocalStorage('selectedStandardSubtopicId')
        return initialSelectedStandardSubtopic
    })

    const [selectedStudent, setSelectedStudent] = useState<StudentResponse | null>(null)

    /**
     * @abstract When any filter changes, refresh lesson search results
     */
    const { response: lessonSearchResults, loading: loadingResults } = useModel({
        model: moduleModel.list,
        onError: (err) => toast.error(err),
        props: {
            grades: selectedGrades,
            categoryId: selectedCategoryId,
            categoryTopicId: selectedCategoryTopicId,
            categorySubtopicId: selectedCategorySubtopicId,
            standardId: selectedStandardId,
            standardTopicId: selectedStandardTopicId,
            standardSubtopicId: selectedStandardSubtopicId,
            keywords: keywordRef.current,
            classId: activeClass?.id ?? null,
            matchAll
        }
    })

    useEffect(() => {
        const subtopic = standardSubtopics.find(st => st.id === selectedStandardSubtopicId)
        setStandardSubtopic(subtopic)
    }, [selectedStandardSubtopicId, standardSubtopics])

    function checkValidField(field, filter, clearFilter) {
        const invalidFilter = filter.find((filterOption) => (filterOption.id === field && filterOption.invalid))
        if(invalidFilter) {
            clearFilter()
        }
    }

    const clearCategory = () => {
        setSelectedCategoryId(null)
    }
    const clearStandard = () => {
        setSelectedStandard(null)
    }

    const refreshFilters = async () => {
        if(!lessonSearchResults) return
        checkValidField(selectedStandardId, lessonSearchResults?.filters.standards, clearStandard)
        setGrades(() => lessonSearchResults.filters.grades.map((grade) => ({
            name: grade.level,
            invalid: grade.invalid,
            selected: Array.isArray(selectedGrades) && selectedGrades !== null && selectedGrades.includes(grade.level)
        })))

        setCategories(lessonSearchResults.filters.categories.map((category) => {
            const selected = (category.id === selectedCategoryId && !category.invalid) || false
            return {
                name: category.name,
                id: category.id as number,
                invalid: category.invalid,
                selected: selected
            }
        }))

        const cat = lessonSearchResults.filters.categories.find(c => c.id === selectedCategoryId)
        setCategoryTopics(cat?.topics.map((topic) => {
            const selected = (topic.id === selectedCategoryTopicId && !topic.invalid) || false
            return {
                name: topic.name,
                id: topic.id as number,
                invalid: topic.invalid,
                selected: selected
            }
        }) ?? [])

        const topics = cat?.topics.find(t => t.id === selectedCategoryTopicId)
        setCategorySubtopics(topics?.subtopics.map((subtopic) => {
            const selected = (subtopic.id === selectedCategorySubtopicId && !subtopic.invalid) || false
            return {
                name: subtopic.name,
                id: subtopic.id as number,
                invalid: subtopic.invalid,
                selected: selected
            }
        }) ?? [])

        setStandards(lessonSearchResults.filters.standards.map((standard) => {
            const selected = (standard.id === selectedStandardId && !standard.invalid) || false
            return {
                name: standard.name,
                id: standard.id as string,
                invalid: standard.invalid,
                selected: selected
            }
        }))
        const standards = lessonSearchResults.filters.standards.find(c => c.id === selectedStandardId)

        setStandardTopics(standards?.topics.map((topic) => {
            const selected = (topic.id === selectedStandardTopicId && !topic.invalid) || false
            return {
                name: topic.name,
                id: topic.id as string,
                invalid: topic.invalid,
                selected: selected
            }
        }) ?? [])

        const top = standards?.topics.find(t => t.id === selectedStandardTopicId)
        setStandardSubtopics(top?.subtopics.map((subtopic) => {
            const selected = (subtopic.id === selectedStandardSubtopicId && !subtopic.invalid) || false
            return {
                name: subtopic.name,
                id: subtopic.id as string,
                invalid: subtopic.invalid,
                selected: selected,
                description: subtopic.description
            }
        }) ?? [])

        const sortedModules = lessonSearchResults.modules.sort((a, b) => (~~b.available - ~~a.available)) // put available modules at the top
        setModules(sortedModules)
    }

    useAfterEffect(() => {
        debounceTimeout.current && clearTimeout(debounceTimeout.current) // restart previous wait/timer on each change
        debounceTimeout.current = setTimeout(() => { // wait for period of time before triggering refresh
            keywordRef.current = keywords
            refreshFilters()
        }, 600)

    }, [keywords])

    /**
     * @abstract When the selected grade changes, update grade local storage
     */
    useAfterEffect(() => {
        if (!selectedStudent) {
            setSelectedCategoryId(null)
            setSelectedStandard(null)
        }
        saveStateToLocalStorage('selectedGrades', selectedGrades)
    }, [selectedGrades])

    /**
     * @abstract When the selected category changes, update category local storage
     */
    useAfterEffect(() => {
        setSelectedCategoryTopicId(null)
        saveStateToLocalStorage('selectedCategoryId', selectedCategoryId);
    }, [selectedCategoryId])

    /**
     * @abstract When the selected category topic changes, update category topic storage
     */
    useAfterEffect(() => {
        setSelectedCategorySubtopicId(null)
        saveStateToLocalStorage('selectedCategoryTopicId', selectedCategoryTopicId);
    }, [selectedCategoryTopicId])

    /**
     * @abstract When the selected standard changes, update standard storage
     */
    useAfterEffect(() => {
        setSelectedStandardTopic(null)
        saveStateToLocalStorage('selectedStandardId', selectedStandardId);
    }, [selectedStandardId])

    /**
     * @abstract When the selected standard topic changes, update standard topic storage
     */
    useAfterEffect(() => {
        setSelectedStandardSubtopic(null)
        saveStateToLocalStorage('selectedStandardTopicId', selectedStandardTopicId);
    }, [selectedStandardTopicId])

    /**
     * @abstract When the selected category subtopic changes, update category subtopic storage
     */
    useAfterEffect(() => {
        saveStateToLocalStorage('selectedCategorySubtopicId', selectedCategorySubtopicId);
    }, [selectedCategorySubtopicId])

    /**
     * @abstract When the selected standard subtopic changes, update standard subtopic storage
     */
    useAfterEffect(() => {
        saveStateToLocalStorage('selectedStandardSubtopicId', selectedStandardSubtopicId);
    }, [selectedStandardSubtopicId])

    /**
     * @abstract When the selected standard subtopic changes, update standard subtopic storage
     */
    React.useEffect(() => {
        refreshFilters()
        // refreshFilters function in useEffect would require select state vars in dependency array, but lessonSearchResults is already updated in useModel when select state vars are changed
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonSearchResults])

    return <main className="container-fluid px-4">
        <RelevantHelpModal helpSteps={helpSteps} videos={helpLinkVideos}/>
        <Row className="min-full-screen full-screen-md flex-column flex-md-row">
            <Col xxl={3} className={`${styles.searchCol} text-start pe-4`}>
                <p className={infoText}>{modules.length > 0 ? `${modules.length} results` : 'No Results found'}</p>

                {students && <>

                {/*****************
                 * Student Filter *
                 ******************/}

                 <LessonFilterHeader
                    title="Student"
                    onClick={() => {
                        setSelectedStudent(null)
                    }}
                 />

                 <LessonFilter
                    type="singleselect"
                    name="Student"
                    items={students.map((student) => ({ invalid: false, name: student.name, selected: student.uuid === selectedStudent?.uuid }))}
                    onSelect={(index) => {
                        const selected = students[index]
                        setSelectedStudent(selected)

                        if (selected.gradeLevel === "None") {
                            setSelectedGrade(null)
                        } else if (selected.gradeLevel) {
                            setSelectedGrade(null)
                            const gradeRanges = classGradeRange[selected.gradeLevel]
                            const clearGrades = grades.map((g) => ({...g, selected: false }))
                            const selectedNames = []
                            for (const range of gradeRanges) {
                                const gradeIndex = grades.findIndex((g) => g.name === range)
                                clearGrades[gradeIndex].selected = true
                                selectedNames.push(clearGrades[gradeIndex].name)
                            }
                            setGrades(clearGrades)
                            setSelectedGrade(selectedNames)
                        }

                        if (selected.standardId === "None") {
                            clearStandard()
                        } else if (selected.standardId) {
                            const standardIndex = standards.findIndex((s) => s.id === selected.standardId)
                            setSelectedItemDropdown(standards, standardIndex, setStandards, setSelectedStandard)
                        }

                        if (selected.categoryId === "None") {
                            clearCategory()
                        } else if (selected.categoryId) {
                            const categoryIndex = categories.findIndex((c) => c.id === parseInt(selected.categoryId))
                            setSelectedItemDropdown(categories, categoryIndex, setCategories, setSelectedCategoryId)
                        }
                    }}
                 />

                 <br />

                </>}


                {/****************
                * Grades Filters *
                ******************/}
                <div className="grade-filter tour-highlight">
                    <LessonFilterHeader title="Grade Level" onClick={() => {setSelectedGrade(null)}}/>
                    <LessonFilter
                        type="multiselect"
                        name="Grade"
                        items={grades}
                        onSelect={(index) => {
                            setSelectedGradeItem(grades, index, setGrades, setSelectedGrade)
                        }}
                    />
                </div>
                {/********************
                * Categories Filters *
                **********************/}
                <br />
                <div className="category-filter">
                    <LessonFilterHeader title="Categories" onClick={clearCategory} />
                    <LessonFilter
                        type="singleselect"
                        name="Category"
                        items={categories}
                        loading={loadingResults}
                        onSelect={(index) => {
                            setSelectedItemDropdown(categories, index, setCategories, setSelectedCategoryId)
                        }}
                    />
                    <p className={`${styles.secondaryText} text-start`}>Subcategory</p>
                    <LessonFilter
                        type="singleselect"
                        name="Subcategory"
                        items={categoryTopics}
                        loading={loadingResults}
                        onSelect={(index) => {
                            setSelectedItemDropdown(categoryTopics, index, setCategoryTopics, setSelectedCategoryTopicId)
                        }}
                    />
                    <p className={`${styles.secondaryText} text-start`}>Section</p>
                    <LessonFilter
                        type="singleselect"
                        name="Section"
                        items={categorySubtopics}
                        loading={loadingResults}
                        onSelect={(index) => {
                            setSelectedItemDropdown(categorySubtopics, index, setCategorySubtopics, setSelectedCategorySubtopicId)
                        }}
                    />
                </div>
                {/*******************
                * Standards Filters *
                *********************/}
                <br />
                <div className="standard-filter">
                    <LessonFilterHeader
                        title="Standards"
                        onClick={clearStandard}
                    />
                    <LessonFilter
                        type="singleselect"
                        name="Standard"
                        items={standards}
                        loading={loadingResults}
                        onSelect={(index) => {
                            setSelectedItemDropdown(standards, index, setStandards, setSelectedStandard)
                        }}
                    />
                    <p className={`${styles.secondaryText} text-start`}>Topic</p>
                    <LessonFilter
                        type="singleselect"
                        name="Topic"
                        items={standardTopics}
                        loading={loadingResults}
                        onSelect={(index) => {
                            setSelectedItemDropdown(standardTopics, index, setStandardTopics, setSelectedStandardTopic)
                        }}
                    />
                    <p className={`${styles.secondaryText} text-start`}>Subtopic</p>
                    <LessonFilter
                        type="singleselect"
                        name="Subtopic"
                        items={standardSubtopics}
                        loading={loadingResults}
                        onSelect={(index) => {
                            setSelectedItemDropdown(standardSubtopics, index, setStandardSubtopics, setSelectedStandardSubtopic)
                        }}
                    />
                    {standardSubtopic?.description && <div className="mt-3 text-muted">
                        <p className="mb-0">{standardSubtopic?.name}</p>
                        <p>{standardSubtopic?.description}</p>
                    </div>}
                </div>
            </Col>
            <Col className="h-100 overflow-auto">
                <Container fluid="xxl" className="px-0">
                    <Row className="my-4 flex-column flex-sm-row">
                        <Col sm={{ span: 4, offset: 4 }}>
                            <Form.Control
                                className="lesson-search-1"
                                name="keywordSearch"
                                type="text"
                                placeholder="Enter keywords to search"
                                value={keywords}
                                onChange={event => {
                                    setKeywords(event.target.value)
                                }}
                            />
                        </Col>
                        <Col sm={4} className="text-start pt-2">
                            <Form.Switch
                                label={matchAll ? "Match All Keywords" : "Match Any Keyword"}
                                checked={matchAll}
                                onChange={toggleMatchAll}
                            />
                        </Col>
                    </Row>
                    <div className="overflow-auto">
                        {loadingResults ? 
                            <>
                                <Skeleton height={50} className={`${styles.bgLightBlue} mb-2`} />
                                <Skeleton height={50} className={`${styles.bgLightBlue} mb-2`} />
                                <Skeleton height={50} className={`${styles.bgLightBlue} mb-2`} />
                            </>
                            :
                            modules?.length > 0 ? 
                                <LessonResults
                                    modules={modules}
                                    onLessonSelection={(lesson) => {
                                        return onLessonSelection(lesson, selectedStudent)
                                    }}
                                    activeClass={activeClass}
                                    filterParams={{
                                        grades: selectedGrades,
                                        categoryId: selectedCategoryId,
                                        categoryTopicId: selectedCategoryTopicId,
                                        categorySubtopicId: selectedCategorySubtopicId,
                                        standardId: selectedStandardId,
                                        standardTopicId: selectedStandardTopicId,
                                        standardSubtopicId: selectedStandardSubtopicId,
                                        keywords: keywordRef.current,
                                        matchAll
                                    }}
                                    viewType={viewType}
                                /> : <p>No Results Found</p> 
                        }
                    </div>
                </Container>
            </Col>
        </Row>
    </main>
}
