import { Container, Button } from 'react-bootstrap'
import { FaqSection, HeroSection, SectionWithImage, TestimonialSection, ModuleSection, PricingSection } from './Components/SectionTemplates'

const faqs = [
        {
            question: "What is STEM Sims and how does it work?",
            answer: "STEM Sims is a comprehensive science curriculum designed for homeschoolers. Our Grades 3-8 comprehensive curriculum also includes over 350 interactive virtual labs and 200 hands-on experiments, allowing students to engage with advanced scientific concepts from home. Course-specific labs for high school science subjects, including AP, are part of the package. The platform is accessible online, with step-by-step guidance through each experiment and simulation."
        },
        {
            question: "What grade levels can use STEM Sims?",
            answer: "STEM Sims is primarily for students in Grades 3-5, 6-8, and 9-12 +AP. Introductory science explorations for Grades K-2 students are a fun starting point for young learners. Our wide range of experiments and simulations can be adapted for various skill levels, making it flexible for the different ages and abilities within your homeschool setting."
        },
        {
            question: "What equipment do I need to use STEM Sims?",
            answer: "For the virtual labs, you only need a computer or tablet with an internet connection. For the 200 hands-on experiments, most materials are common household items. We provide a list of required materials for each experiment, making it so easy to prepare. For the curriculum options, just print out what you need."
        },
        {
            question: "How much does STEM Sims cost?",
            answer: "STEM Sims offers a homeschool subscription plan starting at only $100/year. This provides full access to all 350+ virtual labs and 200 hands-on experiments, making it a very cost-effective alternative to expensive physical lab kits. Add curriculum for Grades 3-5 and Grades 6-8 for an additional $100/year. Lock in this introductory pricing now!"
        },
        {
            question: "I'm concerned about too much screen time. How does STEM Sims address this?",
            answer: "While STEM Sims does include virtual labs, we've balanced this with 200 hands-on experiments. Additionally, the interactive nature of our virtual labs encourages active engagement rather than passive screen time. Many families find that the time saved on setup and cleanup allows for more meaningful science exploration. Optional curriculum books can be printed and completed on paper."
        },
        {
            question: "How effective are virtual labs for science education?",
            answer: "Virtual learning through STEM Sims is highly effective. Our simulations let students interact with common science experiments and even advanced scientific tools not typically available in homes or schools. Combined with the ability to repeat experiments and the self-paced nature of the platform, our platform can accelerate learning. Students show improved understanding and retention of scientific concepts."
        },
        {
            question: "I'm not a science expert. Will I be able to guide my child through STEM Sims effectively?",
            answer: "Absolutely! STEM Sims is designed with homeschool parents in mind. Our platform provides clear, step-by-step instructions for each lab and experiment. Plus, our curriculum is developed by experienced science educators and homeschool parents, ensuring it's both comprehensive and user-friendly."
        },
        {
            question: "What about alignment with educational standards?",
            answer: "Our expert-designed curriculum aligns with state and national science standards to ensure that students can meet or exceed grade-level expectations, preparing them for standardized tests and future academic challenges."
        }
    ]

const testimonials = [
    {
        name: "7th Grade Integrated Science Teacher, Orlando, Florida",
        quote: "I can see that these modules could spark the interest even in those students who dare us to make them interested in science."
    },
    {
        name: "Grade 7 science students in Baltimore, Maryland",
        quote: "Students improved their pre- to post-test scores by more than 45% after they used the STEM Sims Calorimetry module."
    },
    {
        name: "Margaret Honey, Chair, STEM Integration in K-12 Education Committee, National Academy of Engineering",
        quote: "In K-12 education, most STEM teaching and learning focuses on science or mathematics, while comparatively little attention has been paid to technology and engineering."
    },
    {
        name: "Science teacher, Daytona Beach, Florida",
        quote: "Students went from a pre-test average score of 73.2% to a post-test score of 90.4% after they used the Data Analysis simulation. The tests covered experimental design factors, such as independent and dependent variables."
    },
    {
        name: "Science teacher, North Palm Beach, Florida",
        quote: "We did the Trench Dive simulation, and I have to tell you how AMAZING it was! The kids enjoyed it! They were ALL engaged the entire block period (90 minutes). We did all 5 lessons. We talked and discussed water, sunlight, the different ocean levels, and so much more. I REALLY liked the background information. I can't wait to do the next one!"
    }
]

const plans = [
    {
        planName: "Family",
        price: "$100",
        description: "Includes up to 4 students. Lock in this special introductory offer now!",
        features: [
            "350+ Interactive Virtual Labs",
            "200 Hands-On Labs",
            "2,000+ Grade-Level Lessons",
            "10,000+ Assessment Items",
            "How-to Guides for Labs",
            "Teacher Guides",
            "Class Management Tools"
        ],
        studentCount: 4,
        buttonText: "Get Started - Family"
    },
    {
        planName: "Family Plus 3-5",
        price: "$200",
        description: "Teach your little ones with confidence - our Grades 3-5 curriculum is designed just for them.",
        features: [
            "Everything in Family",
            "Adds tailored curriculum for Grades 3-5"
        ],
        studentCount: 4,
        buttonText: "Get Started - Family Plus 3-5"
    },
    {
        planName: "Family Plus 6-8",
        price: "$200",
        description: "Unlock our Grades 6-8 curriculum, designed to engage and educate your older students.",
        features: [
            "Everything in Family",
            "Adds tailored curriculum for Grades 6-8"
        ],
        studentCount: 4,
        buttonText: "Get Started - Family Plus 6-8"
    },
    {
        planName: "Family Plus",
        price: "$300",
        description: "Unlock the ultimate homeschool experience for your entire family! Get everything in Family, plus our comprehensive Grades 3-5 and 6-8 curriculum, tailored to help your students thrive.",
        features: [
            "Everything in Family",
            "All our curriculum for Grades 3-5 and 6-8"
        ],
        studentCount: 4,
        buttonText: "Get Started - Family Plus"
    }
]

export default function Homeschool() {
    return (
        <div style={{ backgroundImage: 'url(/images/landing/hex-back.jpg)', backgroundPosition: 'center' }}>
            <Container fluid="md" className="bg-white shadow px-0">
                <HeroSection title="Homeschool Science from STEM Sims" subtitle="Ignite Curiosity and Delight Children with Exciting Curriculum, Hands-On Projects, and Interactive Virtual Labs." image="/images/landing/homeschool/homeschool-child.png" />
                <SectionWithImage title="Your Affordable Science Teaching Assistant" sectionContents={WhoSection()} image="/images/landing/homeschool/trio.png" imagePosition="left" />
                <TestimonialSection testimonials={testimonials} />
                <SectionWithImage title="Bring Science Into Your Home" sectionContents={HowSection()} image="/images/landing/homeschool/duo2.png" imagePosition="right" />
                <ModuleSection />
                <SectionWithImage title="Backed By Research & Academic Experience" sectionContents={AuthoritySection()} image="/images/landing/homeschool/kidFishEx.png" imagePosition="left" backgroundColor='rgba(114, 124, 200, 0.25)'/>
                <SectionWithImage title="Pathway to Success" sectionContents={VisionSection()} image="/images/landing/homeschool/kid10.png" imagePosition="right" />
                {/* <Section title="Pricing" sectionContents={PricingSection()} backgroundColor='rgba(114, 124, 200, 0.25)'/> */}
                <PricingSection plans={plans} backgroundColor='rgba(114, 124, 200, 0.25)'/>
                <FaqSection faqs={faqs} />
            </Container>
        </div>
    )
}

function WhoSection() {
    return (
        <div className="text-start">
            <p>From complex curriculum to lack of lab equipment, we understand the unique challenges homeschoolers face. That’s why our mission is to make science learning fun, interactive, and stress-free for you and your homeschoolers.</p>

            <p><b>Teaching complex science concepts at home and want the best science experience for your children?</b></p>
            <p>Our hand-on labs use common household materials and our virtual labs teach the same concepts found in the best-equipped science learning environments. Printable books for Grades 3-8 homeschoolers provide guided curriculum developed by expert educators. Assessments identify areas of strengths and weaknesses and help prepare for end-of-year tests.</p>
            <p>With STEM Sims, your homeschoolers learn science within the same environment, from kindergarten through high school, from basic concepts through advanced chemistry, biology, physics, AP, and more.</p>
            
            <p><b>Our pricing is unbeatable, starting at just $100/year for up to 4 students!</b></p>

            <div className="d-flex justify-content-between align-items-center">
                <Button variant="primary" className="mt-4 d-block mb-auto" onClick={() => document.getElementById('pricing').scrollIntoView({ behavior: 'smooth' })}>Sign Up For Success</Button>
                <div className="d-flex-column justify-content-center text-center w-25 bg-light p-2 mt-4 rounded">
                    <a href="https://www.stepupforstudents.org/scholarships/myscholarshop/" className="text-decoration-none text-dark" target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); window.open('https://www.stepupforstudents.org/scholarships/myscholarshop/', 'MyScholarShop');}}>
                        <img src="/images/landing/homeschool/mss-logo.png" alt="MyScholarShop Logo" className="w-100" />
                        Also available in MyScholarShop
                    </a>
                </div>
            </div>

            
        </div>
    )
}

function HowSection() {
    return (
        <div className="text-start">
            <ul>
                <li><strong>Achieve Science Mastery:</strong> Empower your homeschooler with high-quality science education.</li>
                <li><strong>Independent, Self-Paced Learning for Grades K-12:</strong> Nurture curiosity and build confidence as your children explore basic to advanced science concepts.</li>
                <li><strong>Mess-Free, No-Prep Virtual Labs:</strong> More learning, less stress.</li>
                <li><strong>A Wide Range of Experiments:</strong> From Plant Growth to Drones to Wind Tunnels and Mass Spectrometers.</li>
                <li><strong>Expert-Designed:</strong> Trustworthy science education.</li>
            </ul>
            <p>Our secure and effortless integrations allow you to seamlessly incorporate STEM Sims into your learning environment.</p>
        </div>
    )
}

function AuthoritySection() {
    return (
        <div className="text-start">
            <p>Our team of veteran educators and homeschooling parents has over 70 years of combined STEM (Science, Technology, Engineering, and Math) experience. Backed by research conducted over the past decade, we've leveraged cutting-edge technology to create STEM Sims. Studies show significant learning gains on standardized testing with STEM Sims.</p>
            <p>Tied to state and national standards, studies show significant learning gains on standardized testing with STEM Sims.</p>
        </div>
    )
}

function VisionSection() {
    return (
        <div className="text-start">
            <p>With STEM Sims, teaching in-depth science concepts can be turned into an interactive adventure. Fuel your child's curiosity and academic success, while eliminating the stress, cost, and mess of dealing with so many science lab kits.</p>
            <ul>
                <li>Develop strong critical thinking and scientific skills.</li>
                <li>Foster your children’s love for science beyond the curriculum.</li>
                <li>Empower yourself as a confident science educator.</li>
                <li>Enjoy a flexible and efficient homeschool schedule.</li>
                <li>Prepare your child for future STEM opportunities.</li>
            </ul>

            <Button variant="primary" className="mt-4 d-block" onClick={() => document.getElementById('pricing').scrollIntoView({ behavior: 'smooth' })}>Let's Go!</Button>
        </div>
    )
}

