import { Routes, Route, Navigate } from "react-router-dom"
import Sidebar from "../../Sidebar/Sidebar"
import SidebarContent from "../../Sidebar/Content"
import SidebarContainer from "../../Sidebar/SidebarContainer"
import SidebarLinks from "../../Sidebar/Links"
import AuthRoute from "../../Auth/AuthRoute"

import DistrictClasses from "./Classes/Classes"
import WatchedClasses from "./WatchedClasses/WatchedClasses"
import DistrictClassDetails from "./Classes/Info/Info"
import DistrictClassAssignments from "./Classes/Assignments/Assignments"
import DistrictClassStudents from "./Classes/Students/Students"
import DistrictProgress from "./Progress/Progress"
import RelevantHelpModal from "components/Modals/RelevantHelpModal"
import DistrictClassProgress from "./Classes/ClassProgress/DistrictClassProgress"


const helpLinkVideos = [
    {
        "title": "Tracking Student and Class Progress",
        "link": "https://youtube.com/embed/4cNANSwPokU"
    }
]

export default function DistrictDashboard() {
    return (
        <SidebarContainer>
            <RelevantHelpModal videos={helpLinkVideos}/>
            <Sidebar>
                <SidebarLinks
                    links={[
                        {
                            title: "Classes",
                            path: "/dashboard/district/classes"
                        },
                        {
                            title: "Watched Classes",
                            path: "/dashboard/district/watched"
                        },
                        {
                            title: "Progress",
                            path: "/dashboard/district/progress"
                        },
                    ]} />
            </Sidebar>
            <SidebarContent>
                <AuthRoute permission="TEACHER">
                    <Routes>
                        <Route path="/classes" element={<DistrictClasses />} />
                        <Route path="/classes/info" element={<DistrictClassDetails />} />
                        <Route path="/classes/assignments" element={<DistrictClassAssignments />} />
                        <Route path="/classes/students" element={<DistrictClassStudents />} />
                        <Route path="/classes/progress" element={<DistrictClassProgress />} />
                        <Route path="/watched" element={<WatchedClasses />} />
                        <Route path="/progress" element={<DistrictProgress />} />
                        <Route path="*" element={<Navigate to="/dashboard/district/classes" replace={true} />} />
                    </Routes>
                </AuthRoute>
            </SidebarContent>
        </SidebarContainer>
    )
}
