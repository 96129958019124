export default function getPrice(planName: string, quantity: number) {
    let price = 0
    if (planName === "Family Plus") {
        if (quantity <= 4) {
            price = 300
        } else if (quantity < 30) {
            price = quantity * 60
        } else {
            price = quantity * 50
        }
    } else if (planName === "Family Plus 3-5" || planName === "Family Plus 6-8") {
        if (quantity <= 4) {
            price = 200
        } else if (quantity < 30) {
            price = quantity * 40
        } else {
            price = quantity * 30
        }
    }else if (planName === "Family") {
        if (quantity <= 4) {
            price = 100
        } else if (quantity < 30) {
            price = quantity * 20
        } else {
            price = quantity * 15
        }
    } else if (planName === "Class") {
        if (quantity <= 25) {
            price = 375
        } else if (quantity <= 30) {
            price = quantity * 15
        } else {
            price = quantity * 10
        }
    }

    return price
}