import { DrawingAnswer } from "shared/types/studentTypes"

/**
 * Parses an HTML Form into what it would submit as a POST request
 */
function parseFormData(form: HTMLFormElement, parsingLesson: boolean) {
    const formData = {}
    for (const element of form?.elements ?? []) {
        const nameAttribute = element.getAttribute("name")

        // only check the format for lesson questions
        const notMatchLessonFormat = !nameAttribute?.match(/^question-\d+-\d+$/) && !nameAttribute?.match(/^table-\d+$/) && !nameAttribute?.match(/^table-\d+-\d+$/) && nameAttribute !== "started"
        if (parsingLesson && notMatchLessonFormat ) {
            continue
        }
        if (element.tagName === "INPUT" && element.getAttribute("type") === "radio") {
            if ((element as HTMLInputElement).checked) {
                formData[nameAttribute] = (element as HTMLInputElement).value ?? null
            }
        } else if (element.tagName === "INPUT" || element.tagName === "TEXTAREA" || element.tagName === "SELECT") {
            if (element.getAttribute("type") === "checkbox") {
                // If it's a checkbox, skip instructions which should have format (question-1-{number})
                if (nameAttribute && !nameAttribute.includes("question-1-")) {
                    formData[nameAttribute] = (element as HTMLInputElement).checked ? "on" : "off"
                }
            } else {
                formData[nameAttribute] = (element as HTMLInputElement).value ?? null
                if (element.getAttribute("data-table-inputs") !== null) {
                    formData[element.getAttribute("data-table-inputs")] = (element as HTMLInputElement).value ?? null
                }
            }
        }
    }
    return formData
}

function formHasSufficientData(parsedData: any, drawingResponses?: Record<string, DrawingAnswer>) : boolean {

    // Parsed data object needs to have 4 types of properties for example - question-1-1, table-1, table-1-1, s1-q1 started
    if (Object.keys(parsedData).length < 4) {
        return false
    }

    for (const field of Object.keys(drawingResponses ?? {})) {
        if (field.match(/^question-\d+-\d+$/) && !drawingResponses[field]) {
            return false
        }
    }
    const tableData = {}
    for (const field of Object.keys(parsedData)) {
        if (!field.match(/^question-\d+-\d+$/) && !field.match(/^table-\d+$/) && !field.match(/^table-\d+-\d+$/) && !field.match(/^s\d+-q\d+$/) && field !== "started") {
            return false
        }

        if (field === "started") continue

        if (field.match(/^question-\d+-\d+$/) && !parsedData[field]) {
            return false
        }

        // Associate each table with an array of values. For exaple. {1: ["value", "value"], 2: []}
        if (field.match(/^table-\d+-\d+$/)) {
            groupTablesByTableNumbers({key: field, value: parsedData[field], tableData})
        }

        if (field.match(/^s\d+-q\d+$/) && !parsedData[field]) {
            return false
        }
    }
    const eachTableHasContent = checkEachTableContent(tableData)
    return eachTableHasContent
}

/**
 * Returns true if each table has at least one input. False otherwise.
 */
function checkEachTableContent(tableData: Record<string, string>) {
    for (let key in tableData) {
        if (tableData[key].length === 0) {
            return false
        }
    }
    return true
}

/**
 * Group each table number with an array of inputs in that table
 * Example format - {1: ["abc", "cde"], 2: ["efg", "hjk"], 3: []}
 * 
 */
function groupTablesByTableNumbers({key, value, tableData} : {key: string, value: string, tableData: Record<number, string>}) {
    const tableNumber = key.split('-')[1]
    if (!tableData[tableNumber]) {
        if  (value) {
            tableData[tableNumber] = [value]
        } else {
            tableData[tableNumber] = []
        }
    } else {
        if  (value) {
            tableData[tableNumber].push(value)
        }
    }
}

export {
    parseFormData,
    formHasSufficientData,
    groupTablesByTableNumbers,
    checkEachTableContent,
}
