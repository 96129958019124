import Pane from "components/Dashboards/General/Pane";
import { FormikProvider, Form as FormikForm, useFormik, Field } from "formik";
import titleCase from "helpers/titleCase";
import { Fragment, useMemo, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import TextareaField from "./TextareaField";
import { uploadFile } from "shared/routes/curriculum/books";

interface TextField {
    fieldName: string
    inputType: "input" | "textarea"
}

interface ImageField {
    fieldName: string
    inputType: "image"
}

interface UploadField {
    fieldName: string
    inputType: "upload"
    fileTypes: string
}

interface SelectField {
    fieldName: string
    inputType: "select"
    options: readonly string[]
}

interface SpecialField {
    fieldName: string
    inputType: "special"
    render: () => JSX.Element
}

interface CheckboxField {
    fieldName: string
    inputType: "checkbox"
}

export type FieldType = TextField | ImageField | UploadField | SelectField | SpecialField | CheckboxField

interface Props {
    itemTypeName: string
    fields: Array<FieldType>
    data: Record<string, any>
    onSubmit: (values: Record<string, string>) => Promise<any>
}

export default function EditingItemPane({ itemTypeName, fields, data, onSubmit }: Props) {

    const [modal, setModal] = useState(null)
    const [replaceImageState, setReplaceImageState] = useState<{ fieldName: string, old: string, new?: string }>(null)
    const [uploadState, setUploadState] = useState<UploadField>(null)

    const uploadFormControlRef = useRef<HTMLInputElement>()

    const initialData = useMemo(() => {
        return fields.reduce((prev, fieldInfo) => {
            prev[fieldInfo.fieldName] = data?.[fieldInfo.fieldName] ?? ""
            return prev
        }, {})
    }, [data, fields])

    const formik = useFormik({
        initialValues: initialData,
        onSubmit: onSubmit,
        enableReinitialize: true,
    })

    return <>
        <FormikProvider value={formik}>
            <Form as={FormikForm}>
                <Row>
                    <Col>
                        <h4>{titleCase(itemTypeName)} Information</h4>
                    </Col>

                    <Col>
                        <Button variant="theme">Save Changes</Button>
                    </Col>
                </Row>

                <Pane className="text-start">
                    {fields.map((field, index) => (<Fragment key={field.fieldName}>

                        {index !== 0 && <>
                            <hr />
                        </>}

                        <Form.Group
                            className="my-2"
                            controlId={field.fieldName}
                        >
                            { field.inputType !== "checkbox" && <Form.Label>{titleCase(field.fieldName)}</Form.Label> }

                            {(!field.inputType || field.inputType === "input") && <>
                                <Form.Control as={Field} name={field.fieldName} type={field.inputType ?? "input"} onBlur={() => formik.handleSubmit()} />
                            </>}

                            {field.inputType === "textarea" && <>
                                <Form.Control as={TextareaField} rows={5} name={field.fieldName} onBlur={() => formik.handleSubmit()} />
                            </>}

                            {field.inputType === "select" && <>
                                <Field type="select" as={Form.Select} name={field.fieldName} onChange={(e) => {
                                    formik.handleChange(e)
                                    formik.handleSubmit(e)
                                }}>
                                    {field.options?.map((option) => (
                                        <option key={option} value={option}>{option}</option>
                                    ))}
                                </Field>
                            </>}

                            {field.inputType === "image" && <>
                                <br />
                                <img alt="" src={data[field.fieldName]} style={{ maxWidth: "100%", maxHeight: "25vh" }} />
                                <br />
                                <Button variant="theme" onClick={() => {
                                    setModal("replaceImage")
                                    setReplaceImageState({
                                        fieldName: field.fieldName,
                                        old: data[field.fieldName]
                                    })
                                }}>Replace {titleCase(field.fieldName)}</Button>
                            </>}

                            {field.inputType === "upload" && <>
                                <br />
                                <span>{data[field.fieldName]}</span>
                                <br />
                                <Button variant="theme" onClick={() => {
                                    setModal("upload")
                                    setUploadState(field)
                                }}>Replace {titleCase(field.fieldName)}</Button>
                            </>}

                            {field.inputType === "checkbox" && <>
                                <Field as={Form.Check} type="checkbox" label={titleCase(field.fieldName)} name={field.fieldName} onChange={(e) => {
                                    formik.setFieldValue(field.fieldName, e.target.checked)
                                    formik.handleSubmit()
                                }} />
                            </>}

                            {field.inputType === "special" && field.render()}

                        </Form.Group>
                    </Fragment>))}
                </Pane>
            </Form>
        </FormikProvider>

        <Modal show={modal === "replaceImage"} onHide={() => setModal(null)}>
            <Modal.Header closeButton>
                Replace {titleCase(replaceImageState?.fieldName)}
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <Col>
                        <Form.Group className="my-2">
                            <Form.Label>Old {titleCase(replaceImageState?.fieldName)}</Form.Label>
                            <br />
                            <img alt="old book cover" src={replaceImageState?.old} style={{ maxWidth: "100%", maxHeight: "25vh" }} />
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group className="my-2">
                            <Form.Label>New {titleCase(replaceImageState?.fieldName)}</Form.Label>
                            <br />
                            <img alt="new book cover" src={replaceImageState?.new} style={{ maxWidth: "100%", maxHeight: "25vh" }} />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Form.Group>
                        <Form.Label>Paste Image from Library here</Form.Label>
                        <Form.Control onChange={(event) => {
                            const imgTag = event.target.value
                            const match = imgTag.match(/<img src="(.*)" \/>/)
                            if (!match) {
                                toast.error("Please paste in an image copied from the Image Library")
                            } else {
                                setReplaceImageState((prev) => {
                                    const cur = { ...prev }
                                    cur.new = match[1]
                                    return cur
                                })
                            }
                        }} />
                    </Form.Group>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={async () => {
                    await onSubmit({
                        [replaceImageState?.fieldName]: replaceImageState?.new
                    })
                    setModal(null)
                    setReplaceImageState(null)
                }}>Replace</Button>
            </Modal.Footer>
        </Modal>

        <Modal show={modal === "upload"} onHide={() => setModal(null)}>
            <Modal.Header closeButton>
                Replace {titleCase(uploadState?.fieldName)}
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <Form.Group controlId={uploadState?.fieldName}>
                        <Form.Label>{uploadState?.fieldName}</Form.Label>
                        <Form.Control type="file" accept={uploadState?.fileTypes} ref={uploadFormControlRef}></Form.Control>
                    </Form.Group>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={async () => {
                    console.log("Uploading ...")

                    const file = uploadFormControlRef.current?.files[0]
                    const response = await uploadFile({ file })
                    onSubmit({ [uploadState?.fieldName]: "/uploads/" + response.id })
                    setModal(null)
                    setUploadState(null)
                }}>Replace</Button>
            </Modal.Footer>
        </Modal>
    </>
}
