import { useLocation } from "react-router"
import * as teacherRoutes from "shared/routes/teacher"

const ImpersonationBanner = () => {

  const location = useLocation()
  const routes = ["/books", "/help", "/account/password/reset", "/account/recover"] // these routes use FloatingPage component which have absolute positioning and therefore a floating banner is needed
  const showFloatingBanner = routes.some(route => location.pathname.startsWith(route))

  const undoImpersonation = async () => {
      await teacherRoutes.undoImpersonation()
      window.location.href = "/dashboard/teacher/overview"
  }

  return (
    <div className="bg-primary text-white p-2 w-100" style={showFloatingBanner ? {position: "fixed", top: 85, left: "50%", transform: "translate(-50%, -50%)", zIndex: 9999} : null}>
        You're currently logged in as a student. <span role="button" className="fw-bold text-decoration-underline" onClick={undoImpersonation}>Go back</span> to teacher account.
    </div>
  )
}

export default ImpersonationBanner
