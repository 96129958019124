export function highlight(key: string) {
    const element = document.getElementById(key) as HTMLInputElement
    if (!element) return
    element.classList.add("border-top", "border-warning", "border-3", "p-2")
    document.querySelectorAll(`#${key} .incompleteExclamation`).forEach(icon => {
        icon.classList.remove("d-none")
    })
}

export function removeHighlight(key: string) {
    const element = document.getElementById(key) as HTMLInputElement
    if (!element) return
    element.classList.remove("border-top", "border-warning", "border-3", "p-2")
    document.querySelectorAll(`#${key} .incompleteExclamation`).forEach(icon => {
        icon.classList.add("d-none")
    })
}
