import ImpersonationBanner from "components/ImpersonationBanner"
import { InformationReturn } from "../../models/auth"
import Header from "../Header"
export default function WebsiteRoute({auth, route}: {auth: InformationReturn, route: React.ReactElement}) {
    return (
        <>
            <Header
                project="STEM Sims"
                isLoggedIn={auth.isLoggedIn}
                isStudent={auth.isStudent}
                isAdmin={auth.isAdmin}
                isStaff={auth.isStaff}
                trackingEnabled={auth.trackingEnabled}
                csrfToken={auth.csrfToken}
                hasCurriculumAccess={auth.hasCurriculumAccess}
                />
            {auth.impersonationMode && auth.permissions.includes("EDITING") && <ImpersonationBanner />}
            {route}
        </>
    )
}
