import * as React from "react"
import { Row, Col, Button } from "react-bootstrap"
import Pane from "../General/Pane"
import ClassesTable from "../General/ClassesTable"
import { SidebarContext } from "components/Sidebar/SidebarContext"
import CommonClass from "../General/Types/CommonClass"
import Calendar from "../General/Calendar"
import * as teacher from "shared/routes/teacher"
import { toast } from "react-toastify"
import { useModel } from "@stem-sims/nexus"
import styles from "./teacher.module.scss"
import RelevantHelpModal from "components/Modals/RelevantHelpModal"
import { helpLinkVideos, helpSteps } from "./overviewHelpData"
import OverviewAssignments from "../Student/OverviewAssignments"
import Skeleton from "react-loading-skeleton"
import { TeacherAssignmentResponse } from "shared/types/teacherTypes"
import { beforeCurrentDay } from "helpers/dateHelper"
import UngradedSubmissions, { UngradedSubmission } from "../UngradedSubmissions"

interface Props {
    activeClass: CommonClass
    classes: CommonClass[]
    onAddClass: () => void
    onClassSelected: (selectedClass: CommonClass) => void
}

export default function Overview({ activeClass, classes, onClassSelected, onAddClass }: Props) {
    const { collapsed } = React.useContext(SidebarContext)
    const [showArchived, setShowArchived] = React.useState(false)
    const [filteredAssignments, setFilteredAssignments] = React.useState<TeacherAssignmentResponse[]>([])
    const [ungradedSubmissions, setUngradedSubmissions] = React.useState<UngradedSubmission[]>([])

    const unarchivedClasses = classes.filter(item => item.archived === "No")
    const archivedClasses = classes.filter(item => item.archived === "Yes")
    
    const toggleArchived = () => {setShowArchived(!showArchived)}

    const { response: assignments, loading } = useModel({
        model: teacher.getAssignments,
        onError: () => toast.error("Error happened."),
        props: { classID: activeClass?.id }
    })

    React.useEffect(() => {
        if (loading) return

        // Filter out assignments that has no due date or that are already past due
        const filtered = assignments?.filter(a => !beforeCurrentDay(a.dueDate) && a.dueDate) ?? []
        const sortedByDueDate = filtered.sort((first, second) => {
            return new Date(first.dueDate).getTime() - new Date(second.dueDate).getTime()
        })
        setFilteredAssignments(sortedByDueDate)
    }, [assignments, loading])

    React.useEffect(() => {
        window.dispatchEvent(new Event('resize'))
    }, [collapsed])

    React.useEffect(() => {
        if (!assignments || assignments.length === 0 || loading) return

        const submissions: UngradedSubmission[] = []

        assignments.forEach(async assignment => {
            assignment.submissions?.forEach(async submission => {
                if (submission.status === "submitted") {
                    const topicType = !!assignment.topicId
                    const lessonType = !topicType && !!assignment.lessonId

                    submissions.push({
                        assignment: assignment,
                        studentName: submission.student.name, 
                        submissionId: submission.id,
                        submittedDate: submission.modified, 
                        assignmentType: topicType ? "topic" : lessonType ? "lesson" : "assessment"
                    })
                }
            })
        } )
    
        submissions.sort((a, b) => new Date(a.submittedDate).getTime() - new Date(b.submittedDate).getTime())
        setUngradedSubmissions(submissions)
    }, [assignments, loading])

    return (
        <div className="overflow-x-hidden overflow-y-auto">
            <RelevantHelpModal videos={helpLinkVideos} helpSteps={classes.length < 15 ? helpSteps : helpSteps.slice(1)}/>
            <h1 className="text-center mt-3 mb-4 fw-bold">Dashboard</h1>
            <Row>
                <Col xs={12} xl={6}>
                    <Pane className={`shadow-sm p-3 mb-3 ${styles.addClassContainerRow}`}>
                        <div className={styles.addClassContainer}>
                            <Button variant="theme" className={`me-1 ${styles.addClassBtn}`} onClick={onAddClass}>Add&nbsp;Class</Button>
                            {archivedClasses.length > 0 && 
                                <Button onClick={toggleArchived} className={`${styles.addClassBtn} ${showArchived ? "" : "bg-white text-muted"}`}>
                                    {showArchived ? "Hide" : "Show"}&nbsp;Archived
                                </Button>
                            }
                        </div>
                        {(showArchived || unarchivedClasses?.length > 1) &&
                            <>
                                <h3>Classes</h3>
                                <ClassesTable classes={classes} onClassSelected={onClassSelected} activeClass={activeClass} showArchived={showArchived} />
                            </>
                        }
                        {classes?.length === 0 &&
                            <h5 className="mt-2 text-muted text-center">You have no classes. Click the button above to setup your first class.</h5>
                        }
                    </Pane>
                    <UngradedSubmissions ungradedSubmissions={ungradedSubmissions} />
                    {ungradedSubmissions.length === 0 && classes?.length !== 0 &&
                        <>
                            {loading ? <Skeleton height={20} className={`${styles.bgLightBlue} mb-2`} /> : <OverviewAssignments filteredAssignments={filteredAssignments} />}
                        </>}
                </Col>
                <Col xs={12} xl={6}>
                    <Calendar assignments={loading ? [] : assignments} />
                    {ungradedSubmissions.length > 0 && classes?.length !== 0 &&
                        <>
                            {loading ? <Skeleton height={20} className={`${styles.bgLightBlue} mb-2`} /> : <OverviewAssignments filteredAssignments={filteredAssignments} />}
                        </>}
                </Col>
            </Row>
        </div>
    )
}
