import { useModel } from "@stem-sims/nexus"
import { Col, Row } from "react-bootstrap"
import { useParams } from "react-router"
import { PulseLoader } from "react-spinners"
import { createTopic, deleteTopic, getUnits, updateTopic, updateUnit } from "shared/routes/curriculum/books"
import React from "react"
import EditingItemPane from "./EditingItemPane"
import EditingListPane from "./EditingListPane"

export default function EditingUnit() {

    const { bookId, unitId } = useParams()

    const { loading: unitLoading, response: units, refreshModel, } = useModel({
        model: getUnits,
        props: { bookId }
    })

    const unit = units?.find((unit) => unit.id === unitId)

    const onSubmitCallback = React.useCallback(async (values) => {
        await updateUnit({
            unitId: unit?.id,
            name: values.name,
            description: values.description,
            visible: values.visible,
            teacherOnly: values["teacher only"]
        })
        refreshModel()
    }, [unit, refreshModel])

    const createTopicCallback = React.useCallback(async () => {
        await createTopic({
            unitId: unit?.id,
        })
        refreshModel()
    }, [refreshModel, unit?.id])

    const updateTopicCallback = React.useCallback(async (values) => {
        await updateTopic({
            topicId: values.id,
            order: values.order,
        })
        refreshModel()
    }, [refreshModel])

    const deleteTopicCallback = React.useCallback(async (values) => {
        await deleteTopic({ topicId: values.id })
        refreshModel()
    }, [refreshModel])


    if (!unit && unitLoading) {
        return <>
            <PulseLoader />
        </>
    }

    return <>
        <h3>Unit: {unit.name}</h3>
        <Row>
            <Col>
                <EditingItemPane
                    data={{
                        name: unit.name,
                        description: unit.description,
                        visible: unit.visible,
                        "teacher only": unit.teacherOnly,
                    }}
                    fields={[
                        { fieldName: "name", inputType: "input" },
                        { fieldName: "description", inputType: "textarea" },
                        { fieldName: "visible", inputType: "checkbox" },
                        { fieldName: "teacher only", inputType: "checkbox" },
                    ]}
                    itemTypeName="unit"
                    onSubmit={onSubmitCallback}
                />
            </Col>

            <Col>
                <h4>Topics</h4>
                {!unit?.topics && unitLoading && <>
                    <PulseLoader />
                </>}

                {(unit?.topics || !unitLoading) && <>
                    <EditingListPane
                        parent={unit}
                        itemType="topic"
                        items={unit?.topics ?? []}
                        createItem={createTopicCallback}
                        updateItem={updateTopicCallback}
                        deleteItem={deleteTopicCallback}
                    />
                </>}
            </Col>
        </Row>
    </>
}

