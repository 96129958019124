import { useState, useEffect } from "react"
import { Dropdown } from "react-bootstrap"
import books from "shared/routes/curriculum/books"
import { CurriculumUnit, CurriculumTopicWithGradable } from "shared/types/curriculumTypes"
import lessons from "shared/lessons"
import { BeatLoader } from 'react-spinners'

interface Props {
    topicId?: string
    lessonId?: string
    assessmentId?: string
}

export default function DocumentDropdown({ topicId, lessonId, assessmentId }: Props) {
    const [ topic, setTopic ] = useState<CurriculumTopicWithGradable>(null)
    const [ unit, setUnit ] = useState<CurriculumUnit>(null)
    const [ loadingTopicPdf, setLoadingTopicPdf ] = useState(false)
    const [ lessonPath, setLessonPath ] = useState<string>(null)
    const [ brochurePdf, setBrochurePdf ] = useState<string>(null)
    useEffect(() => {
        books.getTopic({ topicId }).then(async (topic) => {
            setTopic(topic)
            if (topic?.unitId) {
                const unit = await books.getUnit({ unitId: topic.unitId })
                setUnit(unit)
            }
        })
    }, [topicId])

    useEffect(() => {
        if (lessonId) {
            lessons.findById(lessonId).then((lesson) => {
                if (lesson && lesson.moduleName && lesson.type) {
                    setBrochurePdf(lesson.brochurePdf)
                    setLessonPath(`/simulations/${ lesson?.moduleName?.toLowerCase().replace(/ /g,'-').replace(/[.']/g, "") }/lessons/lesson-${ lesson?.number }-${ lesson?.type?.toLowerCase() }`)
                }
            })
        }
    }, [lessonId])


    const viewTopicAnswerPdf = (topicId: string) => {
        setLoadingTopicPdf(true)
        window.location.assign(`/api/curriculum/topic/answer-key?topicId=${topicId}`)
        setTimeout(() => {
            setLoadingTopicPdf(false)
        }, 5000)
    }

    const viewTopicPdf = (topicId: string) => {
        setLoadingTopicPdf(true)
        window.location.assign(`/api/curriculum/topic/pdf/Unit ${unit?.number} Topic ${topic?.number}?topicId=${topicId}`)
        setTimeout(() => {
            setLoadingTopicPdf(false)
        }, 5000)
    }



    return <>
        {loadingTopicPdf && (
            <div className="text-center">
                Loading PDF <BeatLoader loading={loadingTopicPdf} size={10} color="#000" />
            </div>
        )}
        <Dropdown>
            <Dropdown.Toggle variant="transparent" className="text-theme border rounded border-theme">
                <i className="far fa-file-alt" /> Documents
            </Dropdown.Toggle>
            <Dropdown.Menu className="border-theme" style={{ zIndex: 1050 }}>
                {topicId && <>
                    <Dropdown.Header>Topic</Dropdown.Header>
                    <Dropdown.Item eventKey="5" onClick={() => viewTopicPdf(topicId)}>Topic PDF</Dropdown.Item>
                    <Dropdown.Item eventKey="6" onClick={() => viewTopicAnswerPdf(topicId)}>Topic Answer PDF</Dropdown.Item>
                </>}
                {lessonId && <>
                    <Dropdown.Header>Lesson</Dropdown.Header>
                    <Dropdown.Item eventKey="2" href={`${lessonPath?.toLowerCase()}.pdf`}>PDF</Dropdown.Item>
                    <Dropdown.Item eventKey="3" href={`${lessonPath?.toLowerCase()}.docx`}>MS Word</Dropdown.Item>
                    {brochurePdf && <>
                        <Dropdown.Header>Lesson Brochure</Dropdown.Header>
                        <Dropdown.Item eventKey="4" href={`${brochurePdf}`}>Brochure</Dropdown.Item>
                    </>}
                </>}
                {assessmentId && <>
                    <Dropdown.Header>Assessment</Dropdown.Header>
                    <Dropdown.Item eventKey="2" href={`/api/assessments/${assessmentId}/guide?teacherView=false`}>Student PDF</Dropdown.Item>
                    <Dropdown.Item eventKey="2" href={`/api/assessments/${assessmentId}/guide?teacherView=true`}>Teacher Guide PDF</Dropdown.Item>
                </>}
            </Dropdown.Menu>
        </Dropdown>
    </>
}
