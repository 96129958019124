import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { Form } from "react-bootstrap"
import { useState } from "react"
import { reportIssue } from "shared/routes/reportRoutes"
import { toast } from "react-toastify"

interface Props {
    show: boolean
    onHide: () => void
}

const ReportIssueModal = ({ show, onHide } : Props) => {
    const [description, setDescription] = useState(null)
    const [sending, setSending] = useState(false)

    const sendReport = async () => {
        setSending(true)
        try {
            await reportIssue({ url: window.location.pathname, description: description })
            toast.success("Issue has been reported.")
        } catch (e) {
            toast.error("An error has occurred when reporting issue.")
        }
        setDescription(null)
        onHide()
        setSending(false)
    }

    return (
        <Modal show={show} centered size="lg" onHide={onHide}>
            <Modal.Header>
                <Modal.Title className="w-100">Report an issue</Modal.Title>
            </Modal.Header>
    
            <Modal.Body>
                <p className="text-start text-muted">Tell us about the issue you have experienced. Be sure to include and details that would help us see the problem so we can fix it.</p>
                <Form>
                    <Form.Group className="mb-3" controlId="description">
                        <Form.Control 
                            as="textarea"
                            className="p-3"
                            placeholder="Please provide relevant details (if applicable), such as assignment name, module, lesson number, topic, and any error messages to help us resolve the issue." 
                            value={description}
                            onChange={(e) => setDescription(e.target.value ?? null)} />
                    </Form.Group>
                </Form>
            </Modal.Body>
    
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button variant="theme" onClick={sendReport} disabled={!description || sending}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ReportIssueModal
