import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import moment from "moment"
import { Link } from "react-router-dom"
import lessons, { LessonResponse } from "shared/lessons"
import * as teacher from "../../../../shared/routes/teacher"
import { AssignmentResponse, ClassResponse, StudentResponse } from "../../../../shared/types/teacherTypes"
import AssignmentForm from "../Forms/AssignmentForm/AssignmentForm"
import { AssignmentContext, AssignmentSection } from "components/Assignment/AssignmentContext"
import DocumentDropdown from "components/Dashboards/Teacher/Assignments/DocumentDropdown"


export class EditAssignmentProps {
    assignmentId: string
}

export default function EditAssignment({ activeClass }: { activeClass: ClassResponse }) {
    const [selectedAssignment, setSelectedAssignment] = React.useState<AssignmentResponse | null>(null)
    const [selectedLesson, setSelectedLesson] = React.useState<LessonResponse>(null)
    const location = useLocation()
    const navigate = useNavigate()
    const state = location.state as { assignmentId: string }
    const [activePart, setActivePart] = React.useState<AssignmentSection>("lesson")
    const [students, setStudents] = React.useState<StudentResponse[]>(null)

    React.useEffect(() => {
        if (typeof state?.assignmentId !== "string") {
            navigate("/dashboard/teacher/assignments")
            //TODO: (STEM-521) Could use some client side error reporting
            toast.error("There was an error navigating to the assignment. Please try to reselect the assignment.")
            return
        }

        void teacher.findAssignmentById(state.assignmentId).then((a) => {
                setSelectedAssignment(a)
                let activePart;
                if(a.topicId) {
                    activePart = "topic"
                } else if (a.lessonId || a.lessonVariety === "Pure Inquiry") {
                    activePart = "lesson"
                } else {
                    activePart = "assessment"
                }
                setActivePart(activePart)
            }).catch((err) => {
                navigate("/dashboard/teacher/assignments")
                if(typeof err === "object") err = err.response?.data.message
                toast.error(err || "There was an error loading the assignment. Please try to reselect the assignment.")
            })
    }, [state?.assignmentId, navigate])

    React.useEffect(() => {
        if (selectedAssignment?.lessonId) {
            void lessons.findById(selectedAssignment.lessonId).then((lesson)=>{
                setSelectedLesson({id: selectedAssignment.lessonId, ...lesson})
            })
        } else {
            setSelectedLesson(null)
        }
    }, [selectedAssignment])

    React.useEffect(() => {
        if (activeClass?.id) {
            teacher.getStudents({ classID: activeClass.id }).then((response) => {
                setStudents(response)
            })
        } else {
            setStudents(null)
        }
    }, [activeClass?.id])

    const assessmentOnly = (selectedAssignment?.assessment && !selectedAssignment.lessonId && selectedAssignment.lessonVariety === "Guided")
    let assignmentType: AssignmentSection = "lesson"
    if (assessmentOnly) {
        assignmentType = "assessment"
    } else if (selectedAssignment?.topicId) {
        assignmentType = "topic"
    }

    const assignmentValues = {
        activePart: activePart,
        setActivePart: setActivePart,
        moduleName: selectedAssignment?.moduleName,
        lessonVariety: selectedAssignment?.lessonVariety,
        type: selectedAssignment?.pureInquiryType,
        guidedLesson: selectedLesson,
        assessmentOnly: assessmentOnly
    }
    return (
        <>
            <div className="d-flex justify-content-between align-items-center m-2">
                <Link style={{
                    "textDecoration": "none"
                }} to="/dashboard/teacher/assignments"><i className="fas fa-arrow-left" /> Return to Assignments</Link>
                <DocumentDropdown
                    topicId={selectedAssignment?.topicId}
                    assessmentId={selectedAssignment?.assessmentId}
                    lessonId={selectedAssignment?.lessonId}
                />
            </div>
            {selectedAssignment && (
                <AssignmentContext.Provider value={assignmentValues}>
                    <AssignmentForm
                        submitButtonText="Save Assignment"
                        initialValues={{
                            title: selectedAssignment.title ?? "",
                            dueDate: selectedAssignment.dueDate ? moment(selectedAssignment.dueDate).format("YYYY-MM-DD") : undefined,
                            notes: selectedAssignment.notes ?? "",
                            assignmentType,
                            studentAssessmentViewOption: selectedAssignment.studentAssessmentViewOption,
                            students: selectedAssignment.students ?? [],
                        }}
                        topicId={selectedAssignment.topicId}
                        assessment={selectedAssignment.assessment}
                        students={students}
                        onSubmit={(values) => {
                            if (selectedAssignment !== null) {
                                const utcDate = moment(values.dueDate).utc().toDate()
                                return teacher.editAssignment({
                                    id: selectedAssignment?.id,
                                    assessmentId: selectedAssignment.assessmentId ?? undefined,
                                    ...values,
                                    dueDate: values.dueDate ? utcDate : undefined,
                                })
                                    .then(() => {
                                        toast.success("The assignment has been modified.")
                                        navigate("/dashboard/teacher/assignments")
                                    })
                                    .catch((err) => {
                                        //TODO: (STEM-521) Could use some client side error reporting
                                        toast.error(err.response?.data?.message ?? "There was an error editing the assignment. Please try again.")
                                    })
                            }
                        }}
                    />
                </AssignmentContext.Provider>
            )}
        </>
    )
}
