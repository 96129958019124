import { useRef, useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons"
import { Button, Modal, Overlay, Popover } from "react-bootstrap"
import { Link } from "react-router-dom"
import HelpTourSystem from "components/Help/HelpTourSystem"
import ReportIssueModal from "./ReportIssueModal"
import useBoolean from "helpers/useBoolean"

type YoutubeVideo = {
    title: string,
    link: string
}

export default function RelevantHelpModal({ videos, helpSteps }: { videos: YoutubeVideo[], helpSteps?}) {
    const [showPopover, setShowPopover] = useState(false)
    const [videoModal, setVideoModal] = useState<{ show: boolean, videoTitle?: string, videoUrl?: string }>({ show: false })
    const [showReportModal, toggleReportModal] = useBoolean(false)
    const [runTour, setRunTour] = useState(false)

    const target = useRef(null)
    const popoverRef = useRef<HTMLDivElement>(null)

    const handlePopoverToggle = () => setShowPopover(prev => !prev)
    const handleVideoClick = (videoTitle: string, videoUrl: string) => {
        setVideoModal({ show: true, videoTitle, videoUrl })
        setShowPopover(false)
    }
    const handleVideoClose = () => setVideoModal({ show: false })

    const handleClickOutside = (event: MouseEvent) => {
        if (popoverRef.current && !popoverRef.current.contains(event.target as Node) && target.current && !target.current.contains(event.target as Node)) {
            setShowPopover(false)
        }
    }

    const handleStartTour = () => {
        setRunTour(true)
        setShowPopover(false)
    }

    const handleReportModal = () => {
        toggleReportModal()
        setShowPopover(false)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div className="position-fixed" style={{ zIndex: 3, right: "2%", bottom: "3%" }}>
            <FontAwesomeIcon
                role="button"
                icon={faCircleQuestion}
                size="xl"
                className="fa-regular bg-white rounded-circle border border-4 border-white"
                color="#404bbd"
                onClick={handlePopoverToggle}
                ref={target}
            />
            <Overlay
                ref={popoverRef}
                show={showPopover}
                target={target.current}
                placement="top"
                containerPadding={20}
            >
                <Popover>
                    <Popover.Body className="d-flex flex-column align-items-center">
                        {videos.map((video) => (
                            <Button className="mb-3" key={video.link} onClick={() => handleVideoClick(video.title, video.link)}>{video.title}</Button>
                        ))}
                        {helpSteps && <Button className="mb-2 btn-success" onClick={handleStartTour}>Start Help Tour</Button>}
                        {videos.length >= 3 && <Link to={'/help'}>See more on the help page</Link>}
                        <Button variant="primary" className="mt-2" onClick={handleReportModal}>Report an issue</Button>
                    </Popover.Body>
                </Popover>
            </Overlay>
            {runTour && (
                <HelpTourSystem
                    steps={helpSteps}
                    run={runTour}
                    setRun={setRunTour}
                />
            )}
            {videoModal.show && (
                <VideoModalButton
                    videoTitle={videoModal.videoTitle!}
                    videoUrl={videoModal.videoUrl!}
                    handleClose={handleVideoClose}
                />
            )}
            <ReportIssueModal show={showReportModal} onHide={toggleReportModal} />
        </div>
    )
}

function VideoModalButton({ videoTitle, videoUrl, handleClose }: { videoTitle: string, videoUrl: string, handleClose: () => void }) {
    return (
        <Modal show onHide={handleClose} size="xl" centered>
            <Modal.Body style={{ 'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto' }}>
                <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                    <iframe
                        title={videoTitle}
                        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                        src={`${videoUrl}?rel=0`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
