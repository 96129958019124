import CustomHTML from "components/CustomHTML"
import { Field } from "formik"
import styles from "./styles.module.scss"
import { Question } from "shared/lessons"
import { useContext, useState } from "react"
import { AssignmentTopicContext } from "components/Assignment/AssignmentTopicContext"
import { removeHighlight } from "helpers/htmlElementHighlight"

type LessonQuestionProps = {
    question: Question,
    sectionNumber: number,
    questionIndex: string,
    questionNumber: number,
    saveLesson: () => void
}

const LessonQuestion = ({question, sectionNumber, questionIndex, questionNumber, saveLesson}: LessonQuestionProps) => {

    const [response, setResponse] = useState("") // this state is added because Field doesn't work properly if onChange is used without state
    const { refreshTopicSectionProgressCheck } = useContext(AssignmentTopicContext)
    const questionId = `question-${sectionNumber}-${questionNumber}` // used for highlighting incomplete question
    return(<div id={questionId}>
        <i className="incompleteExclamation d-none fas fa-exclamation-circle text-warning position-absolute" style={{top: '8px', left: '0'}} />
        <span className="pe-2">{questionIndex}. <CustomHTML html={question.content} /></span>
        <br />
        {question.imageUpdated && <img
            className={`${styles.lessonImage} img-fluid center-block p-1`} src={question.imagePath}
            alt={question.imageAltText ?? 'Question image'}
        />}
        <Field
            as="textarea"
            className={`${styles.lessonInput} input-glow col-6 ms-auto`}
            name={`question-${sectionNumber}-${questionNumber}`}
            onChange={(e) => {
                if (e.target.value) {
                    removeHighlight(questionId)
                }
                setResponse(e.target.value)
            }}
            value={response}
            onBlur={() => {
                saveLesson()
                refreshTopicSectionProgressCheck?.()
            }}
        />
        <div className={`${styles.printHelper} m-0`} id={`print_helper-${ question.number }`}></div>
        <br/>
    </div>)
}

export default LessonQuestion
