import React from "react"
import { Route, Routes, Navigate, useLocation } from "react-router"
import SidebarContainer from "../../Sidebar/SidebarContainer"
import SidebarContent from "../../Sidebar/Content"
import ClassSidebar from "../General/ClassSidebar"
import DashboardAuth from "../General/Auth/DashboardAuth"
import student from "shared/routes/student"
import { ClassResponse } from "shared/types/studentTypes"
import refreshClassesFactory from "../General/Helpers/refreshClassesFactory"

import StudentOverview from "./Overview"
import StudentAssignments from "./StudentAssignments"
import StudentAssignment, { StudentAssignmentState } from "./StudentAssignment"
import StudentGrades from "./StudentGrades"
import Submissions from "./Submissions"
import StudentSubmission, { SubmissionStudentState } from "pages/Dashboard/Student/Grades/Submission/SubmissionStudent"
import checkRouteState from "helpers/checkRouteState"
import { faHome, faTasks, faBook } from "@fortawesome/free-solid-svg-icons"
import RelevantHelpModal from "components/Modals/RelevantHelpModal"

export default function StudentDashboard() {
    const location = useLocation()
    const [classes, setClasses] = React.useState<ClassResponse[]>([])
    const [activeClass, setActiveClass] = React.useState<ClassResponse>(null)
    const [loading, setLoading] = React.useState(true)

    const refreshClasses = refreshClassesFactory({
        getClasses: student.getClasses,
        setLoading,
        setClasses,
        setActiveClass
    })

    const validateGradingRoute = (routeStateClass, Component, url="/dashboard/student/overview") => {
        const validStateSubmission = checkRouteState(location?.state, routeStateClass)
        if(validStateSubmission) {
            location.state = validStateSubmission
            return Component
        }
        return <Navigate to={url} /> 
    }

    // Component did mounted
    React.useEffect(() => {
        void refreshClasses()
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [])

    // Cache active class
    React.useEffect(() => {
        if (activeClass) {
            localStorage.setItem("activeClass", JSON.stringify(activeClass))
        }
    }, [activeClass])

    return (
        <SidebarContainer>
            <RelevantHelpModal videos={[]} />
            <ClassSidebar
                    activeClass={activeClass}
                    classes={classes}
                    onClassSelect={(selectedClass) => {
                        setActiveClass(selectedClass)
                    }}
                    activeLinks={[
                        {
                            title: "Overview",
                            path: "/dashboard/student/overview",
                            icon: faHome
                        },
                        {
                            title: "Assignments",
                            path: "/dashboard/student/assignments",
                            icon: faTasks
                        },
                        {
                            title: "Grades",
                            path: "/dashboard/student/grades",
                            icon: faBook
                        }
                    ]}
                    isStudent
                />
            
            <SidebarContent>
                <DashboardAuth
                    activeClass={activeClass}
                    loading={loading}
                    redirectUrl="/dashboard/student/overview"
                    permission="STUDENT"
                >
                    <Routes>
                        <Route
                            path="/overview"
                            element={
                                <StudentOverview
                                    activeClass={activeClass}
                                />
                            }
                        />

                        <Route
                            path="/assignments"
                            element={
                                <StudentAssignments activeClass={activeClass} />
                            }
                        />

                        <Route
                            path="/assignments/assignment"
                            element={
                                validateGradingRoute(StudentAssignmentState, <StudentAssignment />, "dashboard/student/assignments")
                            }
                        />

                        <Route
                            path="/grades"
                            element={
                                <StudentGrades activeClass={activeClass} />
                            }
                        />

                        <Route
                            path="/grades/submissions"
                            element={
                                <Submissions />
                            }
                        />
                        <Route
                            path="/grades/submission"
                            element={
                                validateGradingRoute(SubmissionStudentState, <StudentSubmission />, "dashboard/student/grades")
                            }
                        />
                        <Route path="*" element={<Navigate to="/dashboard/student/overview" replace={true} />} />
                    </Routes>
                </DashboardAuth>
            </SidebarContent>
        </SidebarContainer>
    )
}
