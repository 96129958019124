import { Modal, Button } from "react-bootstrap"
import { toast } from "react-toastify"
import * as teacher from "../../../../shared/routes/teacher"
import { ClassResponse } from "../../../../shared/types/teacherTypes"
import { useEffect, useState } from "react"
import modules from "../../../../shared/routes/moduleRoutes"

export interface Props {
    activeClass: ClassResponse
    show: boolean
    onHide: () => void
    onSuccess: () => void
}

export function HideModulesModal({ activeClass, onSuccess, onHide, show }: Props) {
    const [availableModules, setAvailableModules] = useState<string[]>([])
    const [hiddenModules, setHiddenModules] = useState<string[]>([])

    useEffect(() => {
        // Get all modules
        const moduleList = modules.list({classId: activeClass.id})
        moduleList.then(moduleList => {
            setAvailableModules(moduleList.modules.map(module => module.name))
        })

        teacher.getHiddenModules({ classId: activeClass.id }).then(hiddenModules => {
            // Remove the modules that are hidden from the available modules
            setHiddenModules(hiddenModules)
        })

    }, [activeClass.id])

    useEffect(() => {
        setAvailableModules(prev => prev.filter(module => !hiddenModules.includes(module)))
    }, [hiddenModules])

    const moveToHidden = (module: string) => {
        const hide = teacher.hideModule({ classId: activeClass.id, module })
        hide.then((res) => {
            if (res.status === 200) {
                setAvailableModules(prev => prev.filter(m => m !== module))
                setHiddenModules(prev => [...prev, module])
            } else {
                toast.error(res.data.message)
            }
        })
    }

    const moveToAvailable = (module: string) => {
        const reveal = teacher.revealModule({ classId: activeClass.id, module })
        reveal.then((res) => {
            if (res.status === 200) {
                setHiddenModules(prev => prev.filter(m => m !== module))
                setAvailableModules(prev => [...prev, module].sort())
            } else {
                toast.error(res.data.message)
            }
        })
    }

    return(
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header className="border-0" closeButton>
                <h1 className="h3 w-100 text-center">Hide Modules</h1>
            </Modal.Header>
            <Modal.Body className="mx-5 text-start">
                <p>Hiding modules will limit student access to them via search.</p>
                <p>Select the modules you would like to hide.</p>
                
                <div className="d-flex flex-column flex-lg-row gap-3 pb-5">
                    <div className="border rounded p-2" style={{maxHeight: "300px", minWidth: "300px", overflowY: "auto"}}>
                        <h6 className="text-center mb-3">Available Modules</h6>
                        <div className="d-flex flex-column gap-2">
                            {availableModules.map(module => (
                                <Button 
                                    key={`available-${module}`}
                                    variant="outline-secondary" 
                                    onClick={() => moveToHidden(module)}
                                    className="text-start"
                                >
                                    {module}
                                </Button>
                            ))}
                        </div>
                    </div>

                    <div className="border rounded p-2" style={{maxHeight: "300px", minWidth: "300px", overflowY: "auto"}}>
                        <h6 className="text-center mb-3">Hidden Modules</h6>
                        <div className="d-flex flex-column gap-2">
                            {hiddenModules.map(module => (
                                <Button 
                                    key={`hidden-${module}`}
                                    variant="outline-danger"
                                    onClick={() => moveToAvailable(module)}
                                    className="text-start"
                                >
                                    {module}
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export function HideModulesButton({onClick, variant = "theme"}: { onClick: () => void, variant?: string }) {
    return (
        <Button onClick={onClick} variant={variant}>
            <i className="fas fa-eye-slash pe-2" />Hide Modules
        </Button>
    )
}
