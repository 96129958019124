import * as React from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Table from "react-bootstrap/Table"
import BeatLoader from "react-spinners/BeatLoader"
import { toast } from "react-toastify"
import * as teacher from "../../../shared/routes/teacher"
import { StudentResponse, ClassResponse } from "../../../shared/types/teacherTypes"
import styles from "./teacher.module.scss"
import ToolTip from "../../General/ToolTip"
import AddStudentModal from "./Forms/AddStudent"
import EditStudentModal from "./Forms/EditStudent"
import DeleteStudentModal from "./Forms/DeleteStudent"
import ResetStudentPasswordModal from "./Forms/ResetStudentPassword"
import EmptyTablePreview from "../General/EmptyTablePreview"
import oauth from "shared/oauth"
import { Col, Row } from "react-bootstrap"
import StudentLoginModal from "./Forms/StudentLogin"
import RelevantHelpModal from "components/Modals/RelevantHelpModal"
import { helpLinkVideos } from "./studentHelpData"
import ClassAccountCreate from "./Forms/ClassAccountCreate"
import useBoolean from "helpers/useBoolean"
import FAIcon from "components/General/FAIcon"

function NoStudentsView({ onSync, onClickAdd, showSync }) {
    return (
        <>
            <EmptyTablePreview
                title="Create Roster"
                description="Once you have added students to your class roster, you will be able to assign assignments, grade submissions, and monitor their progress."
            >
                {showSync ?
                    <Button variant="theme" className="me-2">
                        <span className="fas fa-sync" onClick={onSync}></span>
                    </Button> :
                    <Button variant="theme" onClick={onClickAdd}>
                        + Add Students
                    </Button>
                }
            </EmptyTablePreview>
        </>
    )
}

function StudentTableView({ students, activeClass, onClickEdit, onClickDelete, onClickReset, onClickLogin }: {
    students: StudentResponse[]
    activeClass: ClassResponse
    onClickEdit: (student: StudentResponse) => void
    onClickDelete: (student: StudentResponse) => void
    onClickReset: (student: StudentResponse) => void
    onClickLogin: (student: StudentResponse) => void
}) {

    return (
        <div className="my-4 mx-2">
            <Table>
                <thead>
                    <tr className={styles.customFontSize}>
                        <th className="text-start ps-2">Student Name</th>
                        <th>Student Login Code</th>
                        <th></th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {students.map((student, index) => {
                        return (
                            <tr key={student.uuid}>
                                <ToolTip title="Student Raw Records">
                                    <td
                                        className={`${styles.studentEntry} ${styles.customFontSize} text-start ps-2`}
                                        role="button"
                                        onClick={() => {
                                            window.location.href = `/student/records/${encodeURIComponent(student.uuid)}/${encodeURIComponent(student.name)}`
                                        }}
                                    >
                                        {student.name}
                                    </td>
                                </ToolTip>
                                <ToolTip title="Copy Login Code">
                                    <td
                                        className={`${styles.studentEntry}`}
                                        role="button"
                                        onClick={() => {
                                            navigator.clipboard.writeText(student.studentCode)
                                            toast.info("Copied student code.")
                                        }}
                                    >
                                        <i className="fas fa-copy" /> <span className={styles.customFontSize}>{student.studentCode}</span>
                                    </td>
                                </ToolTip>
                                <td />
                                <td>
                                    <Row className="justify-content-around">
                                        <Col className="mb-2">
                                            <ToolTip title="Log in as Student">
                                                <Button
                                                    variant="success"
                                                    aria-label="Student Login"
                                                    onClick={() => onClickLogin(student)}
                                                >
                                                    <i className="fas fa-door-open"></i>
                                                    <span className={`d-none d-xl-inline-block ${styles.customFontSize}`}>&nbsp;Login as Student</span>
                                                </Button>
                                            </ToolTip>
                                        </Col>
                                        <Col className="mb-2">
                                            <ToolTip title="Reset Student's Password">
                                                <Button
                                                    variant="theme"
                                                    aria-label="Reset Student's Password"
                                                    onClick={() => onClickReset(student)}
                                                >
                                                    <i className="fas fa-undo"></i>
                                                    <span className={`d-none d-xl-inline-block ${styles.customFontSize}`}>&nbsp;Reset Password</span>
                                                </Button>
                                            </ToolTip>
                                        </Col>
                                        <Col className="mb-2">
                                            <ToolTip title="Edit Student's Information">
                                                <Button
                                                    variant="warning"
                                                    aria-label="Edit Student's Information"
                                                    onClick={() => onClickEdit(student)}
                                                >
                                                    <i className="fas fa-edit" />
                                                    <span className={`d-none d-xl-inline-block ${styles.customFontSize}`}>&nbsp;Edit Student</span>
                                                </Button>
                                            </ToolTip>
                                        </Col>
                                        <Col className="mb-2">
                                            <ToolTip title="Delete Student">
                                                <Button
                                                    variant="danger"
                                                    aria-label="Delete Student"
                                                    onClick={() => onClickDelete(student)}
                                                >
                                                    <i className="fas fa-trash-alt" />
                                                    <span className={`d-none d-xl-inline-block ${styles.customFontSize}`}>&nbsp;Delete Student</span>
                                                </Button>
                                            </ToolTip>
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}


export default function Students({ activeClass, refreshClasses }: { activeClass: ClassResponse, refreshClasses: () => void }) {

    //Maybe consider refactoring multiple states to an enumeration or something
    //This works for now but will make it harder to add more actions

    const [remainingLicenses, setRemainingLicenses] = React.useState(null)

    /**
     * Create Class Account Modal Logic
     */
    const [studentClassAccountExists, setStudentClassAccountExists] = React.useState(false)
    const [origin, setOrigin] = React.useState<"AddStudent" | "Auto">("Auto")
    const [createStudentClassAccountShown, setCreateStudentClassAccountShown] = React.useState<boolean>(false)
    const [studentClassAccountCheck, refreshStudentClassAccountCheck] = useBoolean(false)

    /**
     * Create Students Logic
     */
    const [createStudentShown, setCreateStudentShown] = React.useState(false)
    const handleAddClose = () => setCreateStudentShown(false)
    const handleAddShow = () => {
        if (!studentClassAccountExists) {
            toast.warn("Class account is required before adding students to the roster.")
            setOrigin("AddStudent")
            setCreateStudentClassAccountShown(true)
            return
        }
        setCreateStudentShown(true)
    }

    /**
     * Edit Student Logic
     */
    const [editStudentShown, setEditStudentShown] = React.useState(false)
    const handleEditClose = () => {
        setStudentToEdit(null)
        setEditStudentShown(false)
    }
    const handleEditShow = () => setEditStudentShown(true)
    const [studentToEdit, setStudentToEdit] = React.useState<StudentResponse>(null)

    /**
     * Delete Student Logic
     */
    const [deleteStudentShown, setDeleteStudentShown] = React.useState(false)
    const handleDeleteClose = () => setDeleteStudentShown(false)
    const handleDeleteShow = () => setDeleteStudentShown(true)
    const [studentToDelete, setStudentToDelete] = React.useState<StudentResponse>(null)

    /**
     * Reset Student Password Logic
     */
    const [resetStudentShown, setResetStudentShown] = React.useState(false)
    const handleResetClose = () => setResetStudentShown(false)
    const handleResetShow = () => setResetStudentShown(true)
    const [studentToReset, setStudentToReset] = React.useState<StudentResponse>(null)

    /**
     * Student Login Logic
     */
    const [studentLoginShown, setStudentLoginShown] = React.useState(false)
    const handleLoginClose = () => setStudentLoginShown(false)
    const handleLoginShow = () => setStudentLoginShown(true)
    const [studentToLogin, setStudentToLogin] = React.useState<StudentResponse>(null)

    const [students, setStudents] = React.useState<StudentResponse[]>([])
    const [loading, setLoading] = React.useState(true)

    const sort = React.useRef<"first" | "last">(localStorage.getItem("studentSortOrder") as "first" | "last" ?? "last")

    const studentSort = (s1: StudentResponse, s2: StudentResponse) => {
        const firstStudentSort = s1.name.split(" ").at(sort.current === "first" ? 0 : -1)
        const secondStudentSort = s2.name.split(" ").at(sort.current === "first" ? 0 : -1)
        return firstStudentSort.localeCompare(secondStudentSort)
    }

    const refreshStudents = () => {
        setLoading(true)
        return teacher.getStudents({ classID: activeClass.id })
            .then((teacherStudents) => {
                setStudents(teacherStudents.sort(studentSort))
                setLoading(false)
            })
            .catch(() => {
                //Does the class still exist?
                toast.dismiss()
                toast.error("There was an issue getting your students.")
                refreshClasses()
            })
    }

    React.useEffect(() => {
        void refreshStudents()
    },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [activeClass.id])

    React.useEffect(() => {
        (async () => {
            const licenseId = await teacher.teacherLicenseId()
            const { remaining } = await teacher.remainingStudentCount(licenseId)
            setRemainingLicenses(remaining)
        })()
    },[students])

    React.useEffect(() => {
        (async () => {
            const studentClassAccountExists = await teacher.checkIfStudentClassExists()
            setStudentClassAccountExists(studentClassAccountExists)
            setOrigin("Auto")
            setCreateStudentClassAccountShown(!studentClassAccountExists)
        })()
    }, [studentClassAccountCheck])

    return (
        <>
            <RelevantHelpModal videos={helpLinkVideos} />
            <h1 className="h2 text-start mt-3">
                Student Roster
            </h1>
            <p className={`text-start fw-bold ${remainingLicenses > 0 ? "text-success " :"text-danger "}`} >Remaining student licenses: {remainingLicenses ?? 0}</p>
            {students.length > 0 &&
                activeClass.ssoClass ?
                <Button variant="theme" className="float-end">
                    <span className="fas fa-sync" onClick={async () => {
                        await oauth.putOauthClass({
                            classId: activeClass.id
                        })
                        await refreshStudents()
                    }}></span>
                </Button> :
                <div className={`text-start ${styles.greyText} d-grid gap-3 d-xl-flex`}>
                    <div className="my-auto">View student info and add students to the class.</div>
                    <div className="ms-xl-auto my-auto">
                        <Form.Check
                            checked={sort.current === "first"}
                            className="pe-2 my-0" type="switch"
                            label="Sort by first name"
                            onChange={() => {
                                sort.current = sort.current === "first" ? "last" : "first"
                                localStorage.setItem("studentSortOrder", sort.current)
                                setStudents([...students.sort(studentSort)])
                            }} />
                    </div>

                    <div className="d-flex">
                        <div className="text-theme border rounded border-theme px-2 me-2 my-auto btn" onClick={() => {
                            const url = new URL("/printable-student-codes", window.origin)
                            url.searchParams.set("classId", activeClass.id)
                            window.open(url, "_blank")
                        }}>
                            <FAIcon iconName="file-alt" /> Print Student Codes
                        </div>
                        <div>
                            {students.length > 0 && <Button variant="theme" onClick={handleAddShow}>+ Add Students</Button>}
                        </div>
                    </div>
                </div>
            }
            {loading && (
                <div className="d-table h-100 mx-auto">
                    <div className="d-table-cell text-center align-middle">
                        <BeatLoader size={15} />
                    </div>
                </div>
            )}
            {students.length > 0 ?
                <StudentTableView
                    students={students}
                    activeClass={activeClass}
                    onClickEdit={(student) => {
                        setStudentToEdit(student)
                        handleEditShow()
                    }}
                    onClickDelete={(student) => {
                        setStudentToDelete(student)
                        handleDeleteShow()
                    }}
                    onClickReset={(student) => {
                        setStudentToReset(student)
                        handleResetShow()
                    }}
                    onClickLogin={(student) => {
                        setStudentToLogin(student)
                        handleLoginShow()
                    }}
                />
                :
                <NoStudentsView showSync={!!activeClass.ssoClass} onClickAdd={handleAddShow} onSync={async () => {
                    await oauth.putOauthClass({
                        classId: activeClass.id
                    })
                    await refreshStudents()
                }} />
            }

            <AddStudentModal
                show={createStudentShown}
                onHide={handleAddClose}
                onSubmit={() => {
                    refreshClasses()
                    refreshStudents()
                }}
                activeClass={activeClass}
                remainingLicenses={remainingLicenses}
            />

            <EditStudentModal
                show={editStudentShown}
                onHide={handleEditClose}
                onSubmit={() => {
                    handleEditClose()
                    setStudentToEdit(null)
                    void refreshStudents()
                }}
                student={studentToEdit}
            />

            <DeleteStudentModal
                show={deleteStudentShown}
                onHide={handleDeleteClose}
                onSubmit={() => {
                    handleDeleteClose()
                    void refreshStudents()
                }}
                student={studentToDelete}
            />

            <ResetStudentPasswordModal
                show={resetStudentShown}
                onHide={handleResetClose}
                onSubmit={() => {
                    handleResetClose()
                    void refreshStudents()
                }}
                student={studentToReset}
            />

            <StudentLoginModal
                show={studentLoginShown}
                onHide={handleLoginClose}
                onSubmit={async () => {
                    handleLoginClose()
                    await teacher.loginAsStudent(studentToLogin.uuid)
                    window.location.href = "/dashboard/student/overview"
                }}
                student={studentToLogin}
            />

            <ClassAccountCreate
                show={createStudentClassAccountShown}
                hide={() => setCreateStudentClassAccountShown(false)}
                origin={origin}
                afterSubmit={() => {
                    if (origin === "AddStudent") {
                        setCreateStudentShown(true)
                    }
                    refreshStudentClassAccountCheck()
                }} 
            />
        </>
    )
}
