import axios from "axios";

export type Permission = "DEFAULT" | "CUSTOMER" | "ADMIN" | "EDITING" | "STUDENT" | "TEACHER" | "NOT_BEST_IN_CLASS_API"

export interface InformationReturn {
    permissions: (Permission)[],
    isLoggedIn: boolean
    emailVerified: boolean
    isStudent: boolean
    isAdmin: boolean
    isStaff: boolean
    trackingEnabled: boolean
    hasCurriculumAccess: boolean
    csrfToken: string
    triggerAuthRefresh: any
    impersonationMode: boolean
}

/**
 * Gets information about the user's authentication
 */
export function information(): Promise<InformationReturn> {
    return axios.get("/api/auth")
        .then((response) => response.data)
}
