import axios, { AxiosResponse } from "axios"
import compactObject from "../helpers/compactObject"
import { LessonVariety, Type } from "shared/types/moduleTypes"
import encodeURI from "./helpers/encodeURI"
import { Assessment } from "./types/assessmentTypes"
import { StringBoolean } from "./types/commonTypes"
import { Standard } from "./types/teacherTypes"

export type LessonType = Type
export type LessonSlim = {
    module?: string,
    number?: number,
    type?: LessonType,
    title?: string
}

export interface Lesson {
    id: string,
    moduleName: string,
    number: number,
    title: string,
    type: LessonType,
    teacherTips: string,
    teacherPurpose: string,
}

export interface LessonResponse extends Lesson {
    topicId?: string,
    topic?: unknown
    assessmentNumber?: number,
    variety: LessonVariety
    description: string,
    thumbnail?: string,
    pdf?: string,
    teachersGuidePdf?: string
    brochurePdf?: string,
    tableHtml?: string,
    assessment?: Assessment,
    userHasAccess?: boolean
}
export interface Task {
    category: "Question" | "Instruction"
    index: number,
    content: string,
    type: LessonType,
    lessonNumber: number,
    imageUpdated?: Date,
    imageAltText?: string,
    module?: string
    imagePath: string
    isDrawing?: StringBoolean
    drawingAnswerImage?: string
}
export interface Question extends Task {
    number: number
    answerPosition: "Bottom" | "After"
    answer: string
}
export interface Instruction extends Task {
    title: string
}
export type Section = {
    title: string,
    content: (Question|Instruction)[],
}
export interface LessonItem {
    lessonNumber: number
    title: string
    iframe: string
    pdf: string
}

export interface getModuleLessonsReturn {
    walkthrough: string
    lessons: {
        tier1: Array<LessonItem>
        tier2: Array<LessonItem>
    }
}

export type FilterParams = {
    classId?: string,
    grades?: string[], 
    categoryId?: number, 
    categoryTopicId?: number, 
    categorySubtopicId?: number, 
    standardId?: string, 
    standardTopicId?: string, 
    standardSubtopicId?: string, 
    keywords?: string, 
    matchAll?: boolean,
    include?: string[]
}

export function sanitizeParams(params: FilterParams) {
    return {
        grades: sanitizeStringArray(params.grades),
        categoryId: params.categoryId,
        categoryTopicId: params.categoryTopicId,
        categorySubtopicId: params.categorySubtopicId,
        standardId: sanitizeString(params.standardId), 
        standardTopicId: sanitizeString(params.standardTopicId), 
        standardSubtopicId: sanitizeString(params.standardSubtopicId), 
        keywords: sanitizeString(params.keywords), 
        matchAll: params.matchAll,
        include: sanitizeStringArray(params.include)
    } as FilterParams
}

function sanitizeString(uString: string) {
    if (uString) {
        return uString.replace(/[^\w \-():']/g, "")
    } else {
        return null
    }
}

function sanitizeStringArray(uArray: string[]) {
    if (uArray) {
        const sArray: string[] = []
        for (const uString of uArray) {
            if (uString) {
                sArray.push(sanitizeString(uString))
            }
        }
        return sArray
    } else {
        return null
    }
}

/**
* Get a lesson by its ID
*/
function findById(id: string): Promise<LessonResponse> {
    return axios.get(`/api/lessons/${id}`).then((res) => res.data)
}
function editLesson(id,body): Promise<LessonResponse> {
    return axios.put(`/api/editing/lesson/${id}`, body).then((res) => res.data)
}
function getInstructions(id,body): Promise<unknown> {
    return axios.get(`/api/lessons/${id}/instructions`, {
        params: {
            ...body
        }
    }).then((res) => res.data)
}
function editInstruction(body): Promise<unknown> {
    return axios.put("/api/editing/lesson/instruction", body).then((res) => res.data)
}
function getQuestions(id,body): Promise<Question[]> {
    return axios.get(`/api/lessons/${id}/questions`, {
        params: {
            ...body
        }
    }).then((res) => res.data.questions)
}
function editQuestions(id,body): Promise<unknown> {
    return axios.put(`/api/lessons/${id}/questions`, body).then((res) => res.data)
}

async function addSection(lessonId): Promise<unknown> {
    const res = await axios.post("/api/editing/lesson/section", {
        lessonId
    })
    return res.data
}

async function editSection(lessonId, sectionNumber, title): Promise<unknown> {
    const res = await axios.put("/api/editing/lesson/section", {
        lessonId,
        sectionNumber,
        title
    })
    return res.data
}

async function deleteSection(lessonId, sectionNumber): Promise<unknown> {
    const res = await axios.delete("/api/editing/lesson/section", {
        data: {
            lessonId,
            sectionNumber
        }
    })
    return res.data
}

async function addTask(lessonId, sectionNumber, category): Promise<unknown> {
    const res = await axios.post("/api/editing/lesson/task", {
        lessonId,
        sectionNumber,
        category
    })
    return res.data
}

async function editTask(body): Promise<unknown> {
    const res = await axios.put("/api/editing/lesson/task", body)
    return res.data
}

function removeTask(lessonId, sectionNumber, category, listNumber): Promise<AxiosResponse<{message: string}>> {
    return axios.delete("/api/editing/lesson/task", {
        data: {
            lessonId,
            sectionNumber,
            category,
            listNumber
        }
    })
}

function getSections(id,body): Promise<Section[]> {
    return axios.get(`/api/lessons/${id}/sections`, {
        params: {
            ...body
        }
    }).then((res) => res.data.sections)
}

async function getModuleLessons(moduleName: string) {
    const response = await axios.get(`/api/modules/${moduleName}/lessons`)
    return response.data as getModuleLessonsReturn
}

async function deleteLessonTable(lessonId: string) {
    const response = await axios.delete(`/api/lessons/${lessonId}/table`)
    return response
}

async function deleteLessonExample(lessonId: string) {
    const response = await axios.delete(`/api/lessons/${lessonId}/example`)
    return response
}

export type FilterObject = {
    name: string
    id: string | number
    invalid: boolean
}

export type FilterContainer = {
    grades?: {
        level: string
        invalid: boolean
    }[]
    standards?: Array<FilterObject & {
        topics: Array<FilterObject & {
            subtopics: Array<FilterObject & {description: string}>
        }>
    }>
    categories?: Array<FilterObject & {
        topics: Array<FilterObject & {
            subtopics: FilterObject[]
        }>
    }>
}

export interface LessonSearchItem {
    module: string
    number?: number
    available: boolean
    type: LessonType
    title: string
    thumbnail: string
    id?: string
    core: boolean
    variety: LessonVariety
    assessment?: unknown // cannot get Assessments sequelize type
}

export interface LessonSearchParams extends FilterParams {
    moduleName?: string | null,
}

export interface ModuleLessonSearchParams {
    moduleName?: string | null,
    selectedGrade?: string[] | null,
    selectedCategory?: string | null,
    selectedCategoryTopic?: string | null,
    selectedCategorySubtopic?: string | null,
    selectedStandard?: string | null,
    selectedStandardTopic?: string | null,
    selectedStandardSubtopic?: string | null,
    keywords?: string,
    matchAll?: boolean
}

async function list({
    moduleName = null,
    grades = null,
    categoryId = null,
    categoryTopicId = null,
    categorySubtopicId = null,
    standardId = null,
    standardTopicId = null,
    standardSubtopicId = null,
    keywords = "",
    matchAll = false,
}: LessonSearchParams = {}) {
    const { data } = await axios.get("/api/lessons", {
        params: compactObject({
            moduleName,
            grades,
            categoryId,
            categoryTopicId,
            categorySubtopicId,
            standardId,
            standardTopicId,
            standardSubtopicId,
            keywords: keywords,
            matchAll: matchAll,
        })
    })
    return data as LessonSearchItem[]
}

export interface FindStandardsResponse {
    standards: Standard[]
}

export interface FindStandardsProps {
    topicId?: string
    lessonId?: string
    standardId?: string
    core?: boolean
}

/**
 * Returns a set of standards for a particular lesson
 */
function findStandardCorrelations(props: FindStandardsProps): Promise<FindStandardsResponse> {
    return axios.get(encodeURI`/api/lessons/${props.lessonId}/standards`, {
        params: {
            topicId: props.topicId,
            standardId: props.standardId,
            core: props.core
        }
    }).then((response) => response.data)
}

const lessons = {
    findById,
    editLesson,
    list,
    getModuleLessons,
    findStandardCorrelations,
    deleteLessonTable,
    getQuestions,
    editQuestions,
    getInstructions,
    editInstruction,
    getSections,
    addSection,
    editSection,
    deleteSection,
    addTask,
    editTask,
    removeTask,
    deleteLessonExample,
}

export default lessons
