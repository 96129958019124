import FloatingPage from "components/General/FloatingPage"
import styles from "./Subscribe.module.scss"
import { Row, Col, Container, Button, Card, ButtonGroup } from "react-bootstrap"
import { CheckCircleFill } from "react-bootstrap-icons"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { gotoCheckout } from "./StripeSubcribe"
import React, { useContext, useEffect } from "react"
import { Form, Field, useFormik, FormikProvider } from "formik"
import axios from "axios"
import { SubscriptionInfo } from "shared/types/licenseTypes"
import { AuthContext } from "AuthContext"
import getPrice from "./PricingCalc"

type SubscribeState = {
    tab: "school" | "homeschool"
}

const Subscribe = () => {

    const auth = useContext(AuthContext)
    const state = useLocation().state as SubscribeState
    const [studentCount, setStudentCount] = React.useState(4)
    const [familyCost, setFamilyCost] = React.useState(100)
    const [plus35Cost, setPlus35Cost] = React.useState(200)
    const [plus68Cost, setPlus68Cost] = React.useState(200)
    const [plusEverythingCost, setPlusEverythingCost] = React.useState(300)
    const [classCost, setClassCost] = React.useState(375)
    const navigate = useNavigate()
    const [subStatus, setSubStatus] = React.useState<SubscriptionInfo>(null)
    const [showHomeschool, setShowHomeschool] = React.useState(state?.tab !== "school")
    const [showFamilyPlan, setShowFamilyPlan] = React.useState("3-5")

    useEffect(() => {
        //  Get license status of the logged in user
        axios.get("/api/subscription").then(response => {
            setSubStatus(response.data[0])
        })
    }, [])

    const formik = useFormik({
        initialValues: {
            studentCount: 4
        },
        onSubmit: (values) => {
            updatePrice(values.studentCount)
        }
    })

    useEffect(() => {
        if (showHomeschool) {
            updatePrice(4)
        } else {
            updateSchoolPrice(25)
        }
    }, [showHomeschool])

    function toggleHomeschool(value: boolean) {
        setShowHomeschool(value)
        if (value !== showHomeschool) {
            value ? formik.setFieldValue("studentCount", 4) : formik.setFieldValue("studentCount", 25)
        }
    }

    function updatePrice(count: number) {
        // 4 students are included in the base price
        setFamilyCost(getPrice("Family", count))
        setPlus35Cost(getPrice("Family Plus 3-5", count))
        setPlus68Cost(getPrice("Family Plus 6-8", count))
        setPlusEverythingCost(getPrice("Family Plus", count))
        setStudentCount(count)

    }

    function updateSchoolPrice(count: number) {
        //class pricing
        setClassCost(getPrice("Class", count))
        setStudentCount(count)
    }

    function handlePlan (planName: string) {
        if (!auth.isLoggedIn) {
            navigate("/account/sign-up", { state: { studentCount: studentCount, planName: planName } })
            return
        }
        gotoCheckout(planName, studentCount)
    }

  return (
    <FloatingPage className={styles.background}>

        <Container fluid className="p-1">
            <Row className="mb-2">
                <h1 className="fw-bolder">Subscribe to STEM Sims</h1>
                <div className="d-flex justify-content-center my-3">
                    <Button 
                        variant={showHomeschool ? "primary" : "outline-primary"} 
                        onClick={() => toggleHomeschool(true)}
                        className="me-2"
                    >
                        For Homeschool
                    </Button>
                    <Button 
                        variant={!showHomeschool ? "primary" : "outline-primary"} 
                        onClick={() => toggleHomeschool(false)}
                    >
                        For Schools
                    </Button>
                </div>
                {showHomeschool ? (
                    <p className="text-muted w-75 m-auto">We offer two annual plans below so you can get the most from STEM Sims throughout the school year. Perfect for homeschool families looking to enhance their STEM education.</p>
                ) : (
                    <p className="text-muted w-75 m-auto">Empower your K-12 students with our comprehensive STEM Sims annual plans, designed to enhance STEM education throughout the school year.</p>
                )}
                { auth.isLoggedIn && subStatus?.planName && 
                <Card className="w-75 m-auto mt-4 p-4">
                    <p className="w-75 m-auto">You are currently subscribed to the {subStatus.planName} plan.</p>
                    <p className="w-75 m-auto">To manage your subscription, visit your <a href="/account/subscription/">account</a>.</p> 
                </Card>
                }
            </Row>
            <Row>
                <Col md={6} className="mb-5 m-auto">
                    <p className="fw-bold fs-5 m-auto mb-2" >How many students do you teach?</p>
                    <FormikProvider value={formik}>
                            <Form>
                                <div className="d-flex align-items-center">
                                <Field
                                    className="form-range"
                                    type="range"
                                    name="studentCount"
                                    min={showHomeschool ? 4 : 25}
                                    max={showHomeschool ? 30 : 180}
                                    value={formik.values.studentCount}
                                    onChange={async (e) => {
                                        let newVal = parseInt(e.target.value);
                                        if (showHomeschool) {
                                            newVal = newVal < 30 ? newVal : 30
                                            newVal = newVal > 4 ? newVal : 4
                                            updatePrice(newVal);
                                        } else {
                                            newVal = newVal < 180 ? newVal : 180
                                            newVal = newVal > 25 ? newVal : 25
                                            updateSchoolPrice(newVal);
                                        }
                                        await formik.setFieldValue("studentCount", newVal);
                                        
                                    }}
                                />
                                <Field
                                    id="studentCounter"
                                    className="rounded-pill border-0 text-center ms-3"
                                    type="number"
                                    name="studentCount"
                                    min={showHomeschool ? 4 : 25}
                                    max={showHomeschool ? 30 : 180}
                                    value={formik.values.studentCount}
                                    onChange={async (e) => {
                                        let newVal = parseInt(e.target.value);
                                        if (showHomeschool) {
                                            newVal = newVal < 30 ? newVal : 30
                                            newVal = newVal > 4 ? newVal : 4
                                            updatePrice(newVal);
                                        } else {
                                            newVal = newVal < 180 ? newVal : 180
                                            newVal = newVal > 25 ? newVal : 25
                                            updateSchoolPrice(newVal);
                                        }
                                        await formik.setFieldValue("studentCount", newVal);

                                    }}
                                />
                                </div>
                                {showHomeschool && (
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    <ButtonGroup className="w-100">
                                        <Button 
                                            variant={showFamilyPlan === "3-5" ? "primary" : "outline-primary"} 
                                            onClick={() => setShowFamilyPlan("3-5")}
                                            className="border-end"
                                        >
                                            Grades 3-5
                                        </Button>
                                        <Button 
                                            variant={showFamilyPlan === "6-8" ? "primary" : "outline-primary"} 
                                            onClick={() => setShowFamilyPlan("6-8")}
                                        >
                                            Grades 6-8
                                        </Button>
                                        <Button 
                                            variant={showFamilyPlan === "Both" ? "primary" : "outline-primary"} 
                                            onClick={() => setShowFamilyPlan("Both")}
                                        >
                                            Both
                                        </Button>
                                    </ButtonGroup>
                                </div>
                                )}
                            </Form>
                    </FormikProvider>

                </Col>
            </Row>
            {showHomeschool && (
            <Row className="d-flex flex-column flex-md-row justify-content-center">
                <Col md={4} className={`${styles.planCard} me-3 mb-4 text-start d-flex flex-column justify-content-between`}>
                    <p className={`text-muted fw-bold ${styles.planType} mt-2 mb-0 d-flex align-items-center`}>
                        Family
                        {subStatus?.planName === "Family" &&
                            <span className="text-primary fw-bold bg-white p-1 rounded-pill ms-auto small-text">
                                Current Plan
                            </span>
                        }
                    </p>
                    <p><span className="fw-bold fs-2">${familyCost}</span>/year with <span className="fw-bold">{studentCount}</span> students</p>
                    <p className={`text-muted mb-0 ${styles.planDescription}`}>Perfect for students who want to explore STEM through our virtual labs.</p>
                    <hr className={styles.greyLine} />
                    <p className="fw-bolder">What's included?</p>
                    <ul className="p-0">
                        <li className="pb-1"><CheckCircleFill className="me-2 text-primary" /><span className="text-muted">Access to All Virtual Labs</span></li>
                        <li className="pb-1"><CheckCircleFill className="me-2 text-primary" /><span className="text-muted">2,000+ Lessons</span></li>
                        <li className="pb-1"><CheckCircleFill className="me-2 text-primary" /><span className="text-muted">Teacher Guides for All Labs</span></li>
                        <li className="pb-1"><CheckCircleFill className="me-2 text-primary" /><span className="text-muted">Class Management</span></li>
                    </ul>
                    
                    <div className={styles.gap}></div>
                    <Button onClick={() => handlePlan("Family")} className={`${styles.planBtn} rounded-pill w-100`}>Get Started</Button>
                </Col>
                {showFamilyPlan === "Both" ? (
                    <Col md={4} className={`${styles.planCard} ${styles.classPlan} me-3 mb-4 text-start d-flex flex-column justify-content-between`}>
                        <p className={`text-white fw-bold ${styles.planType} mt-2 mb-0 d-flex align-items-center`}>
                            Family Plus Everything
                            {subStatus?.planName === "Family Plus" &&
                                <span className="text-primary fw-bold bg-white p-1 rounded-pill ms-auto small-text">
                                    Current Plan
                                </span>
                            }
                        </p>
                        <p><span className="fw-bold fs-2">${plusEverythingCost}</span>/year with <span className="fw-bold">{studentCount}</span> students</p>
                        <p className={`text-white mb-0 ${styles.planDescription}`}>Get everything in Family, plus: access to our complete curriculum for all grade levels.</p>
                        <hr className={styles.whiteLine} />
                        <p className="fw-bolder fs-6">What's included?</p>
                        <ul className="p-0">
                            <li className="pb-1"><CheckCircleFill className="me-2 text-white" /><span className="text-white">Everything in Family</span></li>
                            <li className="pb-1"><CheckCircleFill className="me-2 text-white" /><span className="text-white">Access to our complete curriculum</span></li>
                            <li className="pb-1"><CheckCircleFill className="me-2 text-white" /><span className="text-white">Assignments integrated with our curriculum</span></li>
                        </ul>
                        <div className={styles.gap}></div>
                        <Button onClick={() => handlePlan("Family Plus")} className="rounded-pill w-100 border-0 bg-white text-primary">Subscribe Now</Button>
                    </Col>
                ) : (
                    <>
                        {showFamilyPlan === "6-8" && (
                            <Col md={4} className={`${styles.planCard} ${styles.classPlan} me-3 mb-4 text-start d-flex flex-column justify-content-between`}>
                                <p className={`text-white fw-bold ${styles.planType} mt-2 mb-0 d-flex align-items-center`}>
                                    Family Plus 6-8
                                    {subStatus?.planName === "Family Plus 6-8" &&
                                        <span className="text-primary fw-bold bg-white p-1 rounded-pill ms-auto small-text">
                                            Current Plan
                                        </span>
                                    }
                                </p>
                                <p><span className="fw-bold fs-2">${plus68Cost}</span>/year with <span className="fw-bold">{studentCount}</span> students</p>
                                <p className={`text-white mb-0 ${styles.planDescription}`}>Get everything in Family, plus: gain access to our new Grades 6-8 curriculum for everything you need to teach your older students.</p>
                                <hr className={styles.whiteLine} />
                                <p className="fw-bolder fs-6">What's included?</p>
                                <ul className="p-0">
                                    <li className="pb-1"><CheckCircleFill className="me-2 text-white" /><span className="text-white">Everything in Family</span></li>
                                    <li className="pb-1"><CheckCircleFill className="me-2 text-white" /><span className="text-white">Access to our curriculum for older learners</span></li>
                                    <li className="pb-1"><CheckCircleFill className="me-2 text-white" /><span className="text-white">Assignments integrated with our curriculum</span></li>
                                </ul>
                                <div className={styles.gap}></div>
                                <Button onClick={() => handlePlan("Family Plus 6-8")} className="rounded-pill w-100 border-0 bg-white text-primary">Subscribe Now</Button>
                            </Col>
                        )}
                        {showFamilyPlan === "3-5" && (
                            <Col md={4} className={`${styles.planCard} ${styles.classPlan} me-3 mb-4 text-start d-flex flex-column justify-content-between`}>
                                <p className={`text-white fw-bold ${styles.planType} mt-2 mb-0 d-flex align-items-center`}>
                                    Family Plus 3-5
                                    {subStatus?.planName === "Family Plus 3-5" &&
                                        <span className="text-primary fw-bold bg-white p-1 rounded-pill ms-auto small-text">
                                            Current Plan
                                        </span>
                                    }
                                </p>
                                <p><span className="fw-bold fs-2">${plus35Cost}</span>/year with <span className="fw-bold">{studentCount}</span> students</p>
                                <p className={`text-white mb-0 ${styles.planDescription}`}>Get everything in Family, plus: gain access to our new Grades 3-5 curriculum for everything you need to teach your younger students.</p>
                                <hr className={styles.whiteLine} />
                                <p className="fw-bolder fs-6">What's included?</p>
                                <ul className="p-0">
                                    <li className="pb-1"><CheckCircleFill className="me-2 text-white" /><span className="text-white">Everything in Family</span></li>
                                    <li className="pb-1"><CheckCircleFill className="me-2 text-white" /><span className="text-white">Access to our curriculum for younger learners</span></li>
                                    <li className="pb-1"><CheckCircleFill className="me-2 text-white" /><span className="text-white">Assignments integrated with our curriculum</span></li>
                                </ul>
                                <div className={styles.gap}></div>
                                <Button onClick={() => handlePlan("Family Plus 3-5")} className="rounded-pill w-100 border-0 bg-white text-primary">Subscribe Now</Button>
                            </Col>
                        )}
                    </>
                )}
            </Row>
            )}
            {!showHomeschool && (
            <Row className="d-flex flex-column flex-md-row justify-content-center">
                <Col md={4}className={`${styles.planCard} ${styles.classPlan} me-3 mb-4 text-start d-flex flex-column justify-content-between`}>
                    <p className={`text-white fw-bold ${styles.planType} mt-2 mb-0 d-flex align-items-center`}>
                        Class
                        {subStatus?.planName === "Class" &&
                            <span className="text-primary fw-bold bg-white p-1 rounded-pill ms-auto small-text">
                                Current Plan
                            </span>
                        }
                    </p>
                    <p><span className="fw-bold fs-2">${classCost}</span>/year with <span className="fw-bold">{studentCount}</span> students</p>
                    <p className={`text-white mb-0 ${styles.planDescription}`}>Empowers teachers with additional features and guidance to provide the best learning experience.</p>
                    <hr className={styles.greyLine} />
                    <p className="fw-bolder">What's included?</p>
                    <ul className="p-0">
                        <li className="pb-1"><CheckCircleFill className="me-2 text-white" /><span className="text-white">Access to All Virtual Labs</span></li>
                        <li className="pb-1"><CheckCircleFill className="me-2 text-white" /><span className="text-white">2,000+ Lessons</span></li>
                        <li className="pb-1"><CheckCircleFill className="me-2 text-white" /><span className="text-white">Teacher Guides for All Labs</span></li>
                        <li className="pb-1"><CheckCircleFill className="me-2 text-white" /><span className="text-white">Class Management</span></li>
                    </ul>
                    
                    <div className={styles.gap}></div>
                    <Button onClick={() => handlePlan("Class")} className={`rounded-pill w-100 border-0 bg-white text-primary`}>Subscribe Now</Button>
                </Col>
                <Col md={4} className={`${styles.planCard} mb-4 text-start d-flex flex-column justify-content-between`}>
                    <p className={` text-muted fw-bold ${styles.planType} mt-2 mb-0 d-flex align-items-center`}>
                        School
                        {subStatus?.planName === "School" &&
                            <span className="text-primary fw-bold bg-white p-1 rounded-pill ms-auto small-text">
                                Current Plan
                            </span>
                        }
                    </p>
                    <p className="fw-bolder fs-2">Contact Us</p>
                    <p className={`text-muted mb-0 ${styles.planDescription}`}>For schools or districts, we work with you to tailor our content with your curriculum and the needs of your students.</p>
                    <hr className={styles.greyLine} />
                    <p className="fw-bolder fs-6">What's included?</p>
                    <ul className="p-0">
                        <li className="pb-1"><CheckCircleFill className="me-2 text-primary" /><span className="text-muted">Everything STEM Sims Offers</span></li>
                        <li className="pb-1"><CheckCircleFill className="me-2 text-primary" /><span className="text-muted">LMS Integration</span></li>
                        <li className="pb-1"><CheckCircleFill className="me-2 text-primary" /><span className="text-muted">Third Party Classroom Integration</span></li>
                        <li className="pb-1"><CheckCircleFill className="me-2 text-primary" /><span className="text-muted">Custom Landing Page</span></li>
                        <li className="pb-1"><CheckCircleFill className="me-2 text-primary" /><span className="text-muted">Professional Development</span></li>
                    </ul>
                    <div className={styles.gap}></div>
                    <Link to="/request-quote" className={`${styles.planBtn} rounded-pill w-100`}>Contact Us</Link>
                </Col>
                </Row>
            )}
        </Container>
    </FloatingPage>
  )
}

export default Subscribe
