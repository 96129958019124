import sharedModules from "shared/routes/moduleRoutes"
import { Type } from "shared/types/moduleTypes"
import { convertImgToBase64 } from "./drawingHelper"
import { DrawingAnswer } from "shared/types/studentTypes"
import { CurriculumSection, TopicStudentAnswers } from "shared/types/curriculumTypes"
import { highlight, removeHighlight } from "helpers/htmlElementHighlight"

export const loadInitialLessonData = async (moduleName: string, type: Type, lessonNumber: number, studentLessonId: string): Promise<[Record<string, string>, Record<string, DrawingAnswer>]> => {
    const response = await sharedModules.getLessonSubmission({
        moduleName: moduleName,
        lessonType: type,
        lessonNumber: lessonNumber,
        studentLessonId: studentLessonId
    })

    const lessonData: Record<string, string> = {}
    const drawingData: Record<string, DrawingAnswer> = {}
    response.responses?.forEach((answer) => {
        if (answer.isDrawing === "No") {
            lessonData[`question-${answer.section}-${answer.questionNumber}`] = answer.answer
        } else {
            drawingData[`question-${answer.section}-${answer.questionNumber}`] = { answer: answer?.answer, drawingResponse: answer?.drawingResponse }
        }
    })

    const rawTableData: { Response: string, ID: number }[] = JSON.parse(response.tableData ?? "[]")
    rawTableData.forEach(answer => {
        lessonData[`table-${answer.ID}`] = answer.Response
    })

    return [lessonData, drawingData]
}

export const loadInitialAssessmentData = async (assessmentId: string, studentId: string, submissionId: string): Promise<[Record<string, string>, Record<string, DrawingAnswer>]> => {
    const response = await sharedModules.getAssessmentSubmission({assessmentId, studentId, submissionId})
    const assessmentData = {}
    const drawingData: Record<string, DrawingAnswer> = {}
    await Promise.all(response.responses?.map(async (answer) => {
        assessmentData[`question-${answer.questionNumber}`] = answer?.answerLetter ?? answer?.drawingResponse ?? ""

        if (answer.isDrawing === "Yes" && answer.drawingResponse) {
            drawingData[`question-${answer.questionNumber}`] = { drawingResponse: await convertImgToBase64(answer.drawingResponse), answer: answer.answer }
        }
    }))
    return [assessmentData, drawingData]
}

export const loadInitialTopicData = async (studentTopicId: string) => {
    const response = await sharedModules.getTopicSubmission({studentTopicId: studentTopicId})
    const topicData = {}

    for (const answer of response.responses) {
        // drawing response from the backend is an image url so it needs to be converted to a base64 string
        let base64String = null
        if (answer.questionType === "Drawing" && answer.drawingResponse) {
            base64String = await convertImgToBase64(answer.drawingResponse)
        }
        topicData[`s${answer.sectionNumber}-q${answer.questionNumber}`] = {topicQuestionId: answer.questionId, answer: answer.answer, drawingResponse: base64String, questionType: answer.questionType}
    }

    response?.tableResponses?.forEach(answer => {
        topicData[`s${answer.sectionNumber}-t${answer.tableNumber}`] = {tableNumber: answer.tableNumber, answer: answer.answer, sectionId: answer.sectionId}
    })

    return topicData
}


interface InitTopicTableProps {
    section: CurriculumSection
    studentAnswers?: any // this is only passed in when loading the existing table data
    setStudentAnswers: React.Dispatch<React.SetStateAction<{}>>
    action: "load" | "attach"
}

export const initTopicTable = ({ section, studentAnswers, setStudentAnswers, action } : InitTopicTableProps) => {
    const sectionTables = document.querySelectorAll("#section-table table")
	let inputCount = 1
    const tableData = {}
	for (let i = 0; i < sectionTables.length; i++) {
        let inputs: Array<HTMLInputElement | HTMLTextAreaElement> = Array.from(sectionTables[i]?.getElementsByTagName("input") ?? [])
        inputs = inputs.concat(...(sectionTables[i]?.getElementsByTagName("textarea") ?? []))

        for (let i = 0; i < inputs.length; i++) {
            const tableNumber = inputCount
            if (action === "attach") {
                attachTopicTableInputListeners(inputs[i], section.sortOrder, section.id, tableNumber, setStudentAnswers)
            } else {
                initTopicTableData(inputs[i], section.sortOrder, section.id, tableNumber, studentAnswers, tableData)
            }
            inputCount++
        }
	}

    const questionTables = document.querySelectorAll(".question-table table")
    let questionNumbers = section.questions.filter(q => q.type === "Table").map(q => q.sortOrder)

    for (let i = 0; i < questionTables.length; i++) {
        let inputs: Array<HTMLInputElement | HTMLTextAreaElement> = Array.from(questionTables[i]?.getElementsByTagName("input") ?? [])
        inputs = inputs.concat(...(questionTables[i]?.getElementsByTagName("textarea") ?? []))

        for (let j = 0; j < inputs.length; j++) {
            const tableNumber = inputCount
            if (action === "attach") {
                attachTopicTableInputListeners(inputs[j], section.sortOrder, section.id, tableNumber, setStudentAnswers, questionNumbers[i])
            } else {
                initTopicTableData(inputs[j], section.sortOrder, section.id, tableNumber, studentAnswers, tableData, questionNumbers[i])
            }
            inputCount++
        }
	}

    // If tables exist, initialize the student table answers based on the number of table inputs
    if (Object.entries(tableData).length > 0) {
        setStudentAnswers(prev => ({ ...prev, ...tableData }))
    }
}

function isInputElement(input: HTMLInputElement | HTMLTextAreaElement): input is HTMLInputElement {
    return input.tagName === "input"
}


export const initTopicTableData = async (input: HTMLInputElement | HTMLTextAreaElement, sectionNumber: number, sectionId: string, tableNumber: number, studentAnswers: any, tableData: any, questionNumber?: number) => {
    const existingAnswer = studentAnswers[`s${sectionNumber}-t${tableNumber}`]?.answer ?? ""
    if (input.type === "checkbox" && isInputElement(input)) {
        input.checked = existingAnswer === "on"
    } else {
        input.value = existingAnswer
    }
    tableData[`s${sectionNumber}-t${tableNumber}`] = {tableNumber: tableNumber, questionNumber: questionNumber ?? null, answer: existingAnswer, sectionId: sectionId, isCheckbox: input.type === "checkbox"}
}

export const attachTopicTableInputListeners = async (input: HTMLInputElement | HTMLTextAreaElement, sectionNumber: number, sectionId: string, tableNumber: number, setStudentAnswers: any, questionNumber?: number) => {
    input.addEventListener("change", (e) => {
        let value: string
        const el = e.target as HTMLInputElement
        if (el.type === "checkbox") {
            value = el.checked ? "on" : ""
        } else {
            value = el.value
        }

        if (value) {
            removeHighlight(`s${sectionNumber}-q${questionNumber}`)
        }
        setStudentAnswers(prev => ({
            ...prev,
            [`s${sectionNumber}-t${tableNumber}`]: {tableNumber: tableNumber, questionNumber: questionNumber ?? null, answer: value, sectionId: sectionId, isCheckbox: el.type === "checkbox"}
        }))
    })
}

export const highlightIncompleteTopicQuestions = (section: CurriculumSection, studentAnswers: TopicStudentAnswers) => {
    const tableRegex = /^s\d+-t\d+$/;

    /**
     * highlight free response, multiple choice or drawing question types
     */
    for (const key of Object.keys(studentAnswers)) {
        if (tableRegex.test(key) || studentAnswers[key].questionType === "Table") continue

        const sectionNum = parseInt(key.split("-")[0].replace("s", ""))
        if (sectionNum !== section.sortOrder) continue

        const questionNum = parseInt(key.split("-")[1].replace("q", ""))
        const elementId = `s${section.sortOrder}-q${questionNum}`

        if ((!studentAnswers[key].answer && !studentAnswers[key].drawingResponse)) {
            highlight(elementId)
        } else {
            removeHighlight(elementId)
        }
    }

    /**
     * remove highlight on all table type questions before (only) incomplete table questions are highlighted
     */
    const allTableQuestions = section.questions.filter(q => q.type === "Table").map(q => q.sortOrder)
    for (const questionNumber of allTableQuestions) {
        const elementId = `s${section.sortOrder}-q${questionNumber}`
        removeHighlight(elementId)
    }

    /**
     * filter all table questions array into a new array that only contains incomplete table questions
     */
    const emptyTableQuestions = Object.keys(studentAnswers).reduce((acc, key) => {
        const { answer, questionNumber } = studentAnswers[key]
        if (!tableRegex.test(key) || questionNumber === null) return acc
        if (answer) {
            acc = acc.filter(v => v !== questionNumber)
        }
        return acc
    }, [...allTableQuestions])

    for (const questionNumber of emptyTableQuestions) {
        const elementId = `s${section.sortOrder}-q${questionNumber}`
        highlight(elementId)
    }
}
