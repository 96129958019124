import axios from "axios"

interface ReportIssueProps {
    url: string
    description: string
}

export async function reportIssue({ url, description } : ReportIssueProps) {
    const res = await axios.post("/api/report-issue", { url, description })
    if (!res.status) {
        throw new Error() 
    }
    return res
}
